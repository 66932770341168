import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { InvertedButton } from "../../components/ThemeButton/ThemeButton";
import { useUserData } from "../../data/UserData";
import RangeInput from "./RangeInput/RangeInput";
import Circles from "../../components/Circles/Circles";
import { AnimatePresence, motion } from "framer-motion";
import { useAudio } from "../../assets/audio/Audio";
import Help from "../../views/Help/Help";
import ToggleInput from "../ChoiceInteraction/ToggleInput/ToggleInput";
import ResultConversation from "../../components/ResultConversation/ResultConversation";

const StyledSlider = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  & > * {
    padding-inline: 20px;
  }

  .flex {
    flex: 1;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .header {
    color: ${({ theme }) => theme.dark};
    font-weight: 700;
    font-size: 1.25em;
    margin-bottom: 40px;
    margin-top: 20px;
  }

  .inputs {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    label {
      font-weight: 900;
      color: ${(props) => props.theme.dark};
      font-size: 1em;
      opacity: 0.5;
    }

    .radio {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;

      & > div {
        flex-shrink: 0;
      }
    }
  }

  .bottom {
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;
    flex-shrink: 0;
  }

  .outputs {
    display: flex;
    color: white;
    font-weight: 700;
    margin-bottom: 22px;
    justify-content: center;
    margin-top: 80px;

    & > * {
      flex: 1;
    }

    .output {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-content: center;
      gap: 10px;
      max-width: 300px;

      img {
        max-height: 71px;
        max-width: 100%;
        margin: 0 auto;
      }

      .value {
        font-size: 1.5em;
      }
    }
  }
`;

const mapInput = (value, input) => {
  if (input.type === "radio") {
    return value ? input.on : input.off;
  }
  return value;
};

const SliderInteraction = ({ config }: { config: ISliderInteraction }) => {
  const { incrementStage, applyPenalty, getCompiled } = useUserData();
  const defaultValue = (input) => {
    if (input.type === "radio") {
      return input.off;
    }
    if (input.type === "hidden") {
      return getCompiled(input.value);
    }
    return input.mapping ? input.mapping[0] : input.min;
  };
  const audio = useAudio();

  const [inputs, setInputs] = useState(config.inputs.map(defaultValue));

  const updateState = (value, i) => {
    const newInputs = [...inputs];
    newInputs[i] = mapInput(value, config.inputs[i]);
    setInputs(newInputs);
  };

  const mappedOutputs = config.outputs.map((o) => {
    const value = o.function(...inputs);
    return {
      value: value,
      img: o.imageFunction ? o.imageFunction(value) : undefined,
    };
  });

  const saveOutputData = useCallback(() => {
    const outputs = config.outputs.reduce((acc, o, i) => {
      if (o.saveVariable)
        return { ...acc, [o.saveVariable]: mappedOutputs[i].value };
      return acc;
    }, {});
    incrementStage({
      userChoices: outputs,
    });
  }, [config.outputs, incrementStage, mappedOutputs]);

  const [result, setResult] = useState<{ text; correct; character? }>(null);

  const closeResult = () => {
    const _result = result;
    setResult(null);
    if (_result && _result.correct) {
      saveOutputData();
    }
  };

  const checkAnswers = useCallback(() => {
    const allCorrect = config.outputs.every((o) =>
      o.correctAnswer !== undefined ? o.correctAnswer(...inputs) : true
    );
    if (allCorrect) {
      audio.play("buttonPress");
      if (config.feedback) {
        setResult({
          text:
            typeof config.feedback.correct === "function"
              ? config.feedback.correct(...inputs)
              : config.feedback.correct,
          correct: true,
          character:
            typeof config.feedback.character === "function"
              ? config.feedback.character(...inputs)
              : config.feedback.character,
        });
      } else saveOutputData();
    } else {
      audio.play("wrongAnswer");
      applyPenalty(config.penalty);
      if (config.feedback) {
        setResult({
          text:
            typeof config.feedback.incorrect === "function"
              ? config.feedback.incorrect(...inputs)
              : config.feedback.incorrect,
          correct: false,
          character:
            typeof config.feedback.character === "function"
              ? config.feedback.character(...inputs)
              : config.feedback.character,
        });
      }
    }
  }, [
    applyPenalty,
    audio,
    config.outputs,
    config.penalty,
    config.feedback,
    inputs,
    saveOutputData,
  ]);

  return (
    <StyledSlider>
      <motion.div
        animate={{ opacity: [0, 1] }}
        className="flex"
        transition={{
          duration: 0.4,
          ease: "easeOut",
          delay: 1,
        }}
      >
        <div className="header">
          {config.help && (
            <span
              style={{
                float: "right",
                width: 32,
                height: 32,
              }}
            ></span>
          )}
          {config.text}
        </div>
        <div className="inputs">
          {config.inputs.map((input, i) => (
            <div className="input" key={i}>
              {input.type === "radio" && (
                <div className="radio">
                  <label>{input.name}</label>
                  <ToggleInput
                    toggle={() => updateState(inputs[i] === input.off, i)}
                    active={inputs[i] === input.on}
                  ></ToggleInput>
                </div>
              )}
              {input.type === "range" && (
                <div className="slider">
                  <RangeInput
                    onChange={(v) => updateState(v, i)}
                    input={input}
                  ></RangeInput>
                </div>
              )}
            </div>
          ))}
        </div>
      </motion.div>

      <motion.div
        className="bottom"
        animate={{
          y: ["100%", "0%"],
        }}
        transition={{
          duration: 0.8,
          ease: "easeOut",
        }}
      >
        <Circles
          inverted
          animate={{
            y: ["100%", "0%"],
          }}
          transition={{
            duration: 0.8,
            ease: "easeOut",
          }}
          style={{
            position: "absolute",
            left: -150,
            right: -150,
            zIndex: -1,
          }}
          gradients={[
            ["light", "main"],
            ["main", "dark"],
          ]}
        />
        <div className="outputs" aria-live="polite">
          {config.outputs
            .filter((o) => !o.hidden)
            .map((output, i) => (
              <div key={i} className="output">
                <div>
                  {typeof output?.name === "function"
                    ? output.name(...inputs)
                    : output?.name}
                  :
                </div>
                <AnimatePresence mode="popLayout">
                  <motion.img
                    alt={mappedOutputs[i].img ? "muntjes" : undefined}
                    key={mappedOutputs[i].img}
                    src={mappedOutputs[i].img}
                    animate={{ opacity: [0, 1] }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.2,
                    }}
                  />
                </AnimatePresence>
                <div className="value">
                  {output.formatter
                    ? output.formatter(mappedOutputs[i].value)
                    : mappedOutputs[i].value}
                </div>
              </div>
            ))}
        </div>

        {config.button && (
          <InvertedButton playSound={false} onClick={checkAnswers}>
            {config.button}
          </InvertedButton>
        )}
      </motion.div>
      {config.help && <Help help={config.help}></Help>}
      <AnimatePresence>
        {result && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <ResultConversation
              close={closeResult}
              text={result.text}
              correct={result.correct}
              character={result.character}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </StyledSlider>
  );
};

export default SliderInteraction;
