import React, { ComponentPropsWithRef } from "react";

import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import IconButton from "../../components/IconButton/IconButton";
import Conversation, {
  ConversationProps,
} from "../../interactions/Conversation/Conversation";
import Noah from "../../assets/characters/Noah/Noah";

interface Props {
  help: Help;
  openButtonPosition?: "top" | "bottom";
  open?: boolean;
}

const StyledHelp = styled.div<Props>`
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;

  pointer-events: none;

  & > .open-close-button {
    position: absolute;
    right: 20px;
    pointer-events: auto;
  }

  .conversation {
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.65)
    );
    z-index: 1;
  }

  &[data-open="true"] {
    pointer-events: auto;
  }
`;

const Help = ({
  help,
  openButtonPosition = "top",
  open = false,
  ...props
}: ComponentPropsWithRef<typeof StyledHelp> & Props) => {
  const [show, setShow] = React.useState(open);

  const { characterImage, text, right, voiceLine, buttonText } = help;

  const config: ConversationProps["config"] = {
    characterImage,
    type: "help",
    steps: Array.isArray(text)
      ? text.map((t, i) => ({
          text: t,
          voiceLine: voiceLine?.[i],
          buttonText:
            (Array.isArray(buttonText) ? buttonText[i] : buttonText) ??
            (text.length > 1 ? "Verder" : "Terug"),
        }))
      : [
          {
            text,
            voiceLine: voiceLine?.[0],
            buttonText: (buttonText as string) ?? "Terug",
          },
        ],
    right: right ?? !Object.values(Noah).includes(characterImage),
  };

  const close = () => setShow(false);

  return (
    <StyledHelp data-open={show} {...props}>
      <AnimatePresence>
        {show && (
          <motion.div
            key="conv"
            className="conversation"
            animate={{
              opacity: [0, 1],
            }}
            exit={{
              opacity: [1, 0],
            }}
            transition={{
              duration: 0.4,
              ease: "easeOut",
            }}
          >
            <AnimatePresence initial={false}>
              <Conversation close={close} config={config} />
            </AnimatePresence>
          </motion.div>
        )}
        {!show && (
          <IconButton
            playSound
            className="open-close-button"
            onClick={() => setShow(!show)}
            icon="QuestionMark"
            style={{
              top: openButtonPosition === "top" ? "20px" : "revert",
              bottom: openButtonPosition === "bottom" ? "20px" : "revert",
            }}
          ></IconButton>
        )}
      </AnimatePresence>
    </StyledHelp>
  );
};

export default Help;
