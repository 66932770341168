import Book from "./char-donorregister-book.svg";
import Default from "./char-donorregister-default.svg";
import Explaining from "./char-donorregister-explaining.svg";

const DonorChar = {
  Book,
  Default,
  Explaining,
};

export default DonorChar;
