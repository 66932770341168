import Noah from "../../../assets/characters/Noah/Noah";
import ZorgverzekeringGuy from "./images/Zorgverzekering/ZorgverzekeringGuy";
import Background from "./images/zorgverzekering-inside_1.svg";
import { Choices, Computed, Variable } from "../../variables";
import getMonth from "../../../util/getMonth";
import { getVoiceLineGenerator } from "../../../util/Voice";

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

const coins = importAll(
  // @ts-ignore
  require.context("./images/coins", false, /\.(png|jpe?g|svg)$/)
);

const voice = getVoiceLineGenerator(
  [
    "Noah-Kristel-05.3-14.mp3",
    "Noah-Kristel-01.31-15.mp3",
    "Zorgverzekering-Max-01.7-01.mp3",
    "Zorgverzekering-Max-01.9-02.mp3",
    "Zorgverzekering-Max-01.11-03.mp3",
    "Zorgverzekering-Max-01.13-04.mp3",
    "Zorgverzekering-Max-01.15-05.mp3",
    "Zorgverzekering-Max-01.17-06.mp3",
    "Zorgverzekering-Max-01.19-07.mp3",
    "20230713-Zorgverzekering-Max-07.17-01.mp3",
    "Zorgverzekering-Max-01.26-09.mp3",
    "Zorgverzekering-Max-01.28-10.mp3",
    "20230713-Zorgverzekering-Max-07.19-02.mp3",
    "Noah-Kristel-06.7-16.mp3",
    "Noah-Kristel-07.1-17.mp3",
    "Noah-Kristel-06.8-18.mp3",
    "Zorgverzekering-Max-01.31-14.mp3",
    "Zorgverzekering-Max-01.6-15.mp3",
    "Noah-Kristel-06.10-19.mp3",
    "Noah-Kristel-06.12-20.mp3",
  ].map((f) => require(`./voice/${f}`))
);

const options = [
  "Zorgverzekering-Max-03_bip_1-11.mp3",
  "Zorgverzekering-Max-03_bip-12.mp3",
  "Zorgverzekering-Max-04_bip-13.mp3",
].map((f) => require(`./voice/${f}`));

const Girl = Noah;
const Guy = ZorgverzekeringGuy;

const repeatedStringOne = `{{ choice = {385: 0, 585: 1, 885: 2}[${Variable(
  "eigenRisicoZorgverzekering"
)}] }}Jij kiest voor €{{= ${Variable(
  "eigenRisicoZorgverzekering"
)} }} *eigen risico*.

Dit betekent in dit spel dat jij elke maand €{{= ${Variable(
  "premieZorgverzekering"
)}.toFixed(2).replace(".", ",") }} aan vaste zorgkosten gaat betalen.`;

const ZorgverzekeringModule: Module = {
  name: "Zorgverzekering",
  resumeBuilding: "zorgverzekering",
  stages: [
    {
      type: "conversation",
      name: "stad 2",
      inCity: true,
      //  redirect: "/city",
      buttonText: "Verder",
      characterImage: Noah.Smiling,
      steps: [
        {
          text: "Eerst gaan we een *zorgverzekering* regelen. Een *zorgverzekering* is in Nederland verplicht!",
          voiceLine: voice(),
        },
        {
          voiceLine: voice(),
          text: "Klik op het juiste gebouw om een *zorgverzekering* te regelen. Daar krijgen we meteen meer informatie.",
          buttonText: "Naar de stad",
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Sluit een *zorgverzekering* af!",
          },
        },
      ],
    },
    {
      type: "conversation",
      name: "convo 1",
      // redirect: "/game",
      buttonText: "Verder",
      right: true,
      characterImage: Guy.HoldingPaper,
      backgroundImage: Background,
      replayHere: true,
      steps: [
        {
          voiceLine: voice(),
          text: "Hi! Goed dat jij een *zorgverzekering* komt afsluiten.",
          characterImage: Guy.Wave,
        },
        {
          voiceLine: voice(),
          text: "Met een *basiszorgverzekering* verzeker je je tegen kosten die je maakt als je ziek wordt.",
        },
        {
          voiceLine: voice(),
          text: "Bij een verzekering betaal je elke maand een *premie*.",
        },
        {
          voiceLine: voice(),
          text: "Dit is een vast bedrag dat je betaalt voor jouw verzekering. Ook als je geen zorg nodig hebt gehad in die maand.",
        },
        {
          voiceLine: voice(),
          text: "Je hebt ook een *eigen risico*. Je betaalt je *eigen risico* alleen als je echt zorg nodig hebt gehad.",
        },
        {
          voiceLine: voice(),
          text: "Het bedrag van je *eigen risico* betaal je maar 1 keer per jaar. Je kan nooit meer betalen dan je *eigen risico*. Je betaalt niets extra, als je geen zorg hebt gehad.",
        },
        {
          voiceLine: voice(),
          text: "Het *eigen risico* is verplicht. Hoeveel premie je betaalt, heeft invloed op je *eigen risico*.",
        },
        {
          voiceLine: voice(),
          text: "Het *verplicht eigen risico* is €385 per jaar. Je kunt het vrijwillig met maximaal €500 ophogen tot €885.",
        },
        {
          voiceLine: voice(),
          text: "Je kunt ook kiezen voor een *vrijwillig eigen risico*.",
        },
        {
          voiceLine: voice(),
          text: "De hoogte van jouw *eigen risico* bepaalt de *premie* die jij per maand betaalt.",

          characterImage: Guy.Explaining,
        },
        {
          voiceLine: voice(),
          text: "Kijk maar welk *eigen risico* jij zou willen.",
          buttonText: "Kies eigen risico",
          characterImage: Guy.ThumbsUp,
        },
      ],
    },
    {
      type: "sliderInteraction",
      text: "Hoeveel eigen risico wil jij?",
      name: "slider 1",
      button: "Verder",
      help: {
        text: "Verplaats de slider om te zien wat de kosten van het eigen risico betekenen. Maak een keuze en klik op verder.",
        characterImage: Girl.Default,
      },
      inputs: [
        {
          name: "Eigen risico",
          type: "range",
          min: 0,
          max: 2,
          step: 1,
          mapping: [385, 585, 885],
          formatter: (value) => "€" + value,
        },
      ],
      outputs: [
        {
          name: "Vaste kosten per jaar",
          function: (a) => ({ 385: 1500, 585: 1356, 885: 1200 }[a]),
          formatter: (value) => "€" + value,
          imageFunction: (a) => coins[`coins-${a}.png`],
        },
        {
          name: "Mogelijke extra kosten per jaar",
          function: (a) => a,
          saveVariable: Choices.eigenRisicoZorgverzekering,
          formatter: (value) => "€" + value,
          imageFunction: (a) => coins[`coins-${a}.png`],
        },
      ],
    },
    {
      type: "updateData",
      results: [
        {
          type: "save",
          location: "computedChoices",
          name: "premieZorgverzekering",
          value: `{{ eigenRisico = ${Variable(
            "eigenRisicoZorgverzekering"
          )}; vasteKosten = { 385: 1500, 585: 1356, 885: 1200 };}}{{= (vasteKosten[eigenRisico] / 12)}}`,
        },
      ],
    },
    {
      type: "conversation",
      name: "convo 2",
      buttonText: "Verder",
      backgroundImage: Background,
      steps: [
        {
          text: repeatedStringOne,
          characterImage: Guy.HoldingPaper,
          right: true,
          voiceLine: () => {
            return options[window["choice"]];
          },
        },
        {
          text: "Als je een verzekering afsluit, kom je woorden tegen die je niet dagelijks hoort.",
          voiceLine: voice(),
          characterImage: Girl.Sweat,
        },
        {
          text: "Weet jij wat ze betekenen? Kies telkens het goede woord bij de beschrijving.",
          voiceLine: voice(),
          buttonText: "Let's go",
          characterImage: Girl.Default,
        },
      ],
    },
    {
      type: "bubblePopperInteraction",
      name: "bubble 1",
      help: {
        text: ["Klik op het woord dat bij de beschrijving past!"],
        characterImage: Girl.Default,
      },
      penalty: 5,
      answers: [
        "polis",
        "aanvullende verzekering",
        "premie",
        "eigen risico",
        "zorgtoeslag",
        "basisverzekering",
      ],
      questions: [
        {
          question: "Dit betaal je elk jaar als je zorg nodig hebt gehad.",
          answers: ["eigen risico"],
          canSkip: true,
        },
        {
          question:
            "Het bedrag wat je aan jouw verzekeraar betaalt voor je verzekering.",
          answers: ["premie"],
          canSkip: true,
        },
        {
          question:
            "Een contract waar alle afspraken over je zorgverzekering instaan.",
          answers: ["polis"],
          canSkip: true,
        },
        {
          question:
            "Dekt de kosten voor zorg van bijvoorbeeld huisarts, ziekenhuis, psychiater of apotheek.",
          answers: ["basisverzekering"],
          canSkip: true,
        },
        {
          question:
            "Geld wat je van de overheid krijgt voor je zorgverzekering.",
          answers: ["zorgtoeslag"],
        },
        {
          question: "Een extra verzekering die je kunt kiezen.",
          answers: ["aanvullende verzekering"],
        },
      ],
    },
    {
      type: "conversation",
      name: "convo 3",
      buttonText: "Verder",
      backgroundImage: Background,
      characterImage: Girl.Default,
      steps: [
        {
          text: "Over die *aanvullende verzekering* moeten we meer weten!",
          voiceLine: voice(),
        },
      ],
    },
    {
      type: "bubblePopperInteraction",
      name: "bubble 2",
      penalty: 5,
      help: {
        text: ["Klik alle woorden aan die aanvullend verzekerd kunnen worden."],
        characterImage: Girl.Default,
      },
      answers: [
        "spoedeisende hulp buitenland",
        "tandarts",
        "reiskosten",
        "studieboeken",
        "bril & lenzen",
        "anticonceptie",
        "mondkapjes",
        "fysio",
      ],
      questions: [
        {
          question: "Wat kun je aanvullend laten verzekeren?",
          answers: [
            "bril & lenzen",
            "fysio",
            "spoedeisende hulp buitenland",
            "tandarts",
            "anticonceptie",
          ],
        },
      ],
    },
    { type: "redirect", redirect: "/rating" },
    {
      type: "conversation",
      buttonText: "Verder",
      characterImage: Guy.Explaining,
      right: true,
      backgroundImage: Background,
      steps: [
        {
          text: "Je weet nu welke kosten en woorden er belangrijk zijn bij een zorgverzekering.",
          voiceLine: voice(),
        },
      ],
    },
    {
      type: "choice",
      text: `Jij koos voor €{{= ${Variable(
        "eigenRisicoZorgverzekering"
      )} }} aan eigen risico. Wil je dat nog veranderen?`,
      skipButton: "niet veranderen",
      header: "Eigen risico kiezen",
      saveVariable: [
        Choices.eigenRisicoZorgverzekering,
        Computed.premieZorgverzekering,
      ],
      choices: [
        {
          value: [385, 1500 / 12],
          display: "€385",
          hideIf: `{{= ${Variable("eigenRisicoZorgverzekering")} === 385 }}`,
        },
        {
          value: [585, 1356 / 12],
          display: "€585",
          hideIf: `{{= ${Variable("eigenRisicoZorgverzekering")} === 585 }}`,
        },
        {
          value: [885, 1200 / 12],
          display: "€885",
          hideIf: `{{= ${Variable("eigenRisicoZorgverzekering")} === 885 }}`,
        },
      ],
    },
    {
      type: "conversation",
      backgroundImage: Background,
      right: true,
      characterImage: Guy.HoldingPaper,
      name: "convo 4",
      buttonText: "Verder",
      steps: [
        {
          text: repeatedStringOne,
          voiceLine: () => {
            return options[window["choice"]];
          },
        },
      ],
      results: [
        {
          type: "save",
          location: "userChoices",
          name: Choices.aanvullendeVerzekeringTandarts,
          value: 0,
        },
        {
          type: "save",
          location: "userChoices",
          name: Choices.aanvullendeVerzekeringBrilLenzen,
          value: 0,
        },
        {
          type: "save",
          location: "userChoices",
          name: Choices.aanvullendeVerzekeringSpoedeisendeHulpBuitenland,
          value: 0,
        },
        {
          type: "save",
          location: "userChoices",
          name: Choices.aanvullendeVerzekeringFysio,
          value: 0,
        },
      ],
    },
    {
      type: "choice",
      text: "Wat wil jij aanvullend nog verzekeren?",
      multi: true,
      header: "Aanvullende verzekering kiezen",
      button: "Verder",
      skipButton: "Geen",
      choices: [
        {
          value: [15],
          display: "Tandarts (+€15)",
          saveVariable: [Choices.aanvullendeVerzekeringTandarts],
        },
        {
          value: [40],
          display: "Bril & lenzen (+€40)",
          saveVariable: [Choices.aanvullendeVerzekeringBrilLenzen],
        },
        {
          value: [3],
          display: "Spoedeisende hulp buitenland (+€3)",
          saveVariable: [
            Choices.aanvullendeVerzekeringSpoedeisendeHulpBuitenland,
          ],
        },
        {
          value: [10],
          display: "Fysio (+€10)",
          saveVariable: [Choices.aanvullendeVerzekeringFysio],
        },
        {
          value: [7],
          display: "Anticonceptie (+€7)",
          saveVariable: [Choices.aanvullendeVerzekeringAnticonceptie],
        },
      ],
    },
    {
      type: "updateData",
      results: [
        {
          type: "save",
          location: "computedChoices",
          name: Computed.maandelijksePremie,
          value: `{{= ${Variable("premieZorgverzekering")} + ${Variable(
            "aanvullendeVerzekeringTandarts"
          )} + ${Variable("aanvullendeVerzekeringBrilLenzen")} + ${Variable(
            "aanvullendeVerzekeringSpoedeisendeHulpBuitenland"
          )} + ${Variable("aanvullendeVerzekeringFysio")} + ${Variable(
            "aanvullendeVerzekeringAnticonceptie"
          )} }}`,
        },
      ],
    },
    {
      type: "conversation",
      name: "convo 5",
      buttonText: "Verder",
      backgroundImage: Background,
      characterImage: Girl.Default,
      steps: [
        {
          characterImage: Guy.HoldingPaper,
          right: true,
          text: `Nu wordt jouw *premie* voor je *zorgverzekering* €{{= ${Variable(
            "maandelijksePremie"
          )}.toFixed(2).replace(".", ",") }}. Dit wordt elke maand van je rekening afgetrokken.`,
          voiceLine: voice(),
        },
        {
          text: "Dit kost wel wat geld. Fijn dat we nu weten wat mijn maximale kosten zijn. Dat geld zetten we apart!",
          voiceLine: voice(),
        },
        {
          text: "Zorg dat je jouw *eigen risico* op elk moment kunt betalen.",
          buttonText: "Naar de bank",
          voiceLine: voice(),
        },
      ],
      results: [
        {
          type: "bank",
          description: `Zorgverzekering ${getMonth()}`,
          amount: `{{= -${Variable("maandelijksePremie")} }}`,
          key: "zorgverzekering",
          monthly: true,
        },
      ],
    },
    {
      type: "redirect",
      redirect: "/bank",
    },
  ],
};

export default ZorgverzekeringModule;
