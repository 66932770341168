import callingImg from "./char-zorgtoeslag-calling.svg";
import checkingTimeImg from "./char-zorgtoeslag-checking-time.svg";
import defaultImg from "./char-zorgtoeslag-default.svg";
import holdingPaperImg from "./char-zorgtoeslag-holding-paper.svg";
import pointingImg from "./char-zorgtoeslag-pointing.svg";
import showTabletImg from "./char-zorgtoeslag-show-tablet.svg";
import thumbsUpImg from "./char-zorgtoeslag-thumbs-up.svg";
import waveImg from "./char-zorgtoeslag-wave.svg";

const ZorgToeslagCharacter = {
  Calling: callingImg,
  CheckingTime: checkingTimeImg,
  Default: defaultImg,
  HoldingPaper: holdingPaperImg,
  Pointing: pointingImg,
  ShowTablet: showTabletImg,
  ThumbsUp: thumbsUpImg,
  Wave: waveImg,
};

export default ZorgToeslagCharacter;
