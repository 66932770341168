import LoonHeffingsCharacterDefault from "./char-loonheffing-default.svg";
import LoonHeffingsCharacterSweat from "./char-loonheffing-sweat.svg";
import LoonHeffingsCharacterWaving from "./char-loonheffing-waving.svg";

const LoonHeffingsCharacter = {
  Default: LoonHeffingsCharacterDefault,
  Sweat: LoonHeffingsCharacterSweat,
  Waving: LoonHeffingsCharacterWaving,
};

export default LoonHeffingsCharacter;
