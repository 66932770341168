import styled from "styled-components";
import PopUp from "../../components/PopUp/PopUp";
import ThemeButton from "../../components/ThemeButton/ThemeButton";
import React from "react";

const StyledPopUp = styled(PopUp)`
  font-weight: 700;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .buttons {
    display: flex;
    gap: 10px;
    flex-direction: column;

    .emphasis {
      font-size: 1.25em;
    }
  }
`;
const ReplayPopUp = ({
  onCancel,
  onConfirm,
}: {
  onCancel?: () => void;
  onConfirm?: () => void;
}) => (
  <StyledPopUp>
    <div className="header emphasis">Opnieuw proberen</div>
    <div>Weet je zeker dat je deze module opnieuw wilt spelen?</div>
    <div className="buttons">
      <ThemeButton onClick={onCancel}>Annuleren</ThemeButton>
      <ThemeButton onClick={onConfirm}>Speel Opnieuw!</ThemeButton>
    </div>
  </StyledPopUp>
);

export default ReplayPopUp;
