import HomeImg from "./buildings/home.svg";
import BankImg from "./buildings/bank.svg";
import BankGeslotenImg from "./buildings/bank-gesloten.svg";
import AchievementImg from "./buildings/achievement-showroom.svg";
import AchievementGeslotenImg from "./buildings/achivement-showroom-gesloten.svg";
import ZorgToeslagImg from "./buildings/zorgtoeslag-open.svg";
import ZorgToeslagGeslotenImg from "./buildings/zorgtoeslag-gesloten.svg";
import ZorgVerzekeringImg from "./buildings/zorgverzekering-open.svg";
import ZorgVerzekeringGeslotenImg from "./buildings/zorgverzekering-gesloten.svg";
import AansprakelijkheidsverzekeringImg from "./buildings/aansprakelijkheidsverzekering-open.svg";
import AansprakelijkheidsverzekeringGeslotenImg from "./buildings/aansprakelijkheidsverzekering-gesloten.svg";
import StufiImg from "./buildings/stufi-open.svg";
import StufiGeslotenImg from "./buildings/stufi-gesloten.svg";
import StageVergoedingImg from "./buildings/stagevergoeding-open.svg";
import StageVergoedingGeslotenImg from "./buildings/stagevergoeding-gesloten.svg";
import LoonHeffingImg from "./buildings/loonheffing-open.svg";
import LoonHeffingGeslotenImg from "./buildings/loonheffing-gesloten.svg";
import QuizImg from "./buildings/quiz-open.svg";
import QuizGeslotenImg from "./buildings/quiz-closed.svg";
import PosterImg from "./buildings/abri.svg";
import DonorImg from "./buildings/donorregister_open.svg";
import DonorGeslotenImg from "./buildings/donorregister_closed.svg";
import BiebImg from "./buildings/bibliotheek-open.svg";
import StemBureauImg from "./buildings/stembureau-open.svg";
import StemBureauGeslotenImg from "./buildings/stembureau-closed.svg";

import BankLottie from "./buildings/bank.lottie";
import AchievementsLottie from "./buildings/achievements.lottie";
import HuisLottie from "./buildings/huis.lottie";
import ZorgverzekeringLottie from "./buildings/zorgverzekering.lottie";
import ZorgtoeslagLottie from "./buildings/zorgtoeslag.lottie";
import AansprakelijkheidsverzekeringLottie from "./buildings/aansprakelijkheid.lottie";
import StufiLottie from "./buildings/stufi.lottie";
import StageLottie from "./buildings/stage.lottie";
import LoonheffingLottie from "./buildings/loonheffing.lottie";
import QuizLottie from "./buildings/quiz.lottie";
import DonorLottie from "./buildings/Deur_donorregister.lottie";
import BiebLottie from "./buildings/Deur_bibliotheek.lottie";
import StemBureauLottie from "./buildings/deur_stembureau.lottie";

import ZorgToeslagCharacter from "../Modules/2-ZorgToeslagModule/images/Zorgtoeslag/ZorgToeslag";
import ZorgverzekeringGuy from "../Modules/1-ZorgverzekeringModule/images/Zorgverzekering/ZorgverzekeringGuy";
import AansprakelijkheidCharacter from "../Modules/5-AansprakelijkheidsModule/images/Aansprakelijkheid/AansprakelijkheidCharacter";
import StufiCharacter from "../Modules/4-StufiModule/images/Stufi/StufiCharacter";
import StageVergoedingCharacter from "../Modules/6-StageVergoedingModule/images/character/StageVergoedingCharacter";
import LoonHeffingsCharacter from "../Modules/7-LoonheffingsModule/images/character/LoonHeffingsCharacter";
import Quizmaster from "../Modules/9-EindQuiz/images/char-quizmaster/Quizmaster";
import DonorChar from "../Modules/3-DonorModule/images/character/DonorChar";
import { StemBureauCharacter } from "../Modules/8-Stemmen/StemModule";

export const buildings: Building[] = [
  {
    left: 60,
    image: HomeImg,
    width: 250,
    name: "Huis nummer 10",
    goTo: "/game",
    playSound: "door1",
    lottie: HuisLottie,
  },
  {
    left: 60,
    width: 600,
    image: BankImg,
    name: "bank",
    goTo: "/bank",
    playSound: "door1",
    closedImage: BankGeslotenImg,
    closedUntil: [0, 3],
    lottie: BankLottie,
  },
  {
    left: 80,
    image: StufiImg,
    name: "stufi",
    closedUntil: [4, 0],
    closedImage: StufiGeslotenImg,
    width: 180,
    goTo: "/game",
    linkedModule: 4,
    lottie: StufiLottie,
    closedCharacter: StufiCharacter.PhoneInHand,
    playSound: "door2",
  },
  {
    left: 80,
    width: 177,
    image: ZorgVerzekeringImg,
    name: "zorgverzekering",
    goTo: "/game",
    closedUntil: [1, 0],
    closedImage: ZorgVerzekeringGeslotenImg,
    linkedModule: 1,
    playSound: "door2",
    lottie: ZorgverzekeringLottie,
    closedCharacter: ZorgverzekeringGuy.Calling,
  },
  {
    left: 80,
    width: 350,
    image: StemBureauImg,
    name: "stembureau",
    goTo: "/game",
    closedUntil: [1, 0],
    closedImage: StemBureauGeslotenImg,
    linkedModule: 8,
    playSound: "door2",
    lottie: StemBureauLottie,
    closedCharacter: StemBureauCharacter.Default,
  },
  {
    left: 80,
    width: 192,
    image: ZorgToeslagImg,
    name: "zorgtoeslag",
    closedUntil: [2, 2],
    goTo: "/game",
    closedImage: ZorgToeslagGeslotenImg,
    playSound: "door2",
    linkedModule: 2,
    lottie: ZorgtoeslagLottie,
    closedCharacter: ZorgToeslagCharacter.Calling,
  },
  {
    left: 70,
    width: 175,
    image: AchievementImg,
    name: "achievements",
    goTo: "/achievements",
    playSound: "door1",
    closedImage: AchievementGeslotenImg,
    closedUntil: [1, 17],
    lottie: AchievementsLottie,
  },
  {
    left: 90,
    image: QuizImg,
    name: "quiz",
    closedUntil: [8, 3],
    closedImage: QuizGeslotenImg,
    width: 320,
    goTo: "/game",
    linkedModule: 9,
    closedCharacter: Quizmaster.Default,
    lottie: QuizLottie,
    playSound: "door2",
  },
  {
    left: 60,
    image: AansprakelijkheidsverzekeringImg,
    name: "aansprakelijkheidsverzekering",
    closedUntil: [5, 0],
    closedImage: AansprakelijkheidsverzekeringGeslotenImg,
    width: 170,
    goTo: "/game",
    linkedModule: 5,
    lottie: AansprakelijkheidsverzekeringLottie,
    closedCharacter: AansprakelijkheidCharacter.Calling,
    playSound: "door2",
  },
  {
    left: 87,
    image: StageVergoedingImg,
    name: "stage",
    closedUntil: [6, 0],
    closedImage: StageVergoedingGeslotenImg,
    width: 230,
    goTo: "/game",
    linkedModule: 6,
    closedCharacter: StageVergoedingCharacter.Default,
    lottie: StageLottie,
    playSound: "door2",
  },
  {
    left: 60,
    width: 270,
    image: DonorImg,
    closedImage: DonorGeslotenImg,
    closedUntil: [3, 0],
    name: "ziekenhuis",
    goTo: "/game",
    linkedModule: 3,
    lottie: DonorLottie,
    playSound: "door2",
    closedCharacter: DonorChar.Book,
  },
  {
    left: 50,
    image: LoonHeffingImg,
    name: "loonheffing",
    closedUntil: [7, 0],
    closedImage: LoonHeffingGeslotenImg,
    width: 180,
    goTo: "/game",
    linkedModule: 7,
    closedCharacter: LoonHeffingsCharacter.Default,
    lottie: LoonheffingLottie,
    playSound: "door2",
  },
  {
    left: 130,
    name: "bibliotheek",
    goTo: "/library",
    width: 450,
    lottie: BiebLottie,
    image: BiebImg,
    playSound: "door1",
  },
  {
    left: 100,
    image: PosterImg,
    name: "poster",
    width: 50,
    goTo: "/poster",
  },
];
