import { useParallax } from "react-scroll-parallax";
import React, { useEffect } from "react";
import Building from "./Building/Building";
import Raccoon from "./raccoon.lottie";
import { Player } from "@lottiefiles/react-lottie-player";
import { buildings } from "../../data/Buildings/Buildings";

export const ParallaxPavement = () => {
  const { ref, controller } = useParallax<HTMLDivElement>({
    speed: 85,
  });

  useEffect(() => {
    const width =
      controller.viewEl instanceof HTMLDivElement
        ? controller.viewEl.clientWidth
        : 320;
    ref.current.style.left = `${-625 + (width - 320) * 0.165}px`;
    controller.update();
  });

  return (
    <div className="pavement-back" ref={ref}>
      <div
        style={{
          position: "absolute",
          bottom: 96,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        {buildings.map((building, index) => (
          <Building key={index} building={building}></Building>
        ))}
        <Player
          className="lottie"
          src={Raccoon}
          autoplay
          loop
          style={{
            bottom: -11,
            width: 50,
            left: 1500,
          }}
        ></Player>
      </div>
    </div>
  );
};
