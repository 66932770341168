import React, { useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

import LezenSchrijvenLogo from "../../assets/logos/lsLogo.svg";
import Circles from "../../components/Circles/Circles";
import { InvertedButton } from "../../components/ThemeButton/ThemeButton";
import { useUserData } from "../../data/UserData";
import { NavLink, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const StyledStart = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
  }

  & > div {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .header {
      flex: 1;
      display: grid;
      place-items: center;
      padding-inline: 20px;

      h1,
      p {
        margin: 0;
        color: ${({ theme }) => theme.dark};
        font-weight: 500;
        font-family: var(--emphasis-font);
      }

      p {
        font-size: 1.75em;
        white-space: pre-line;
      }

      h1 {
        font-size: 3em;
        line-height: 0.8em;
      }

      a {
        color: ${({ theme }) => theme.light};
      }
    }
  }
`;

const finalSlide = 4;

const transition = (delay = 0, duration = 0.4) => ({
  duration,
  ease: "easeOut",
  delay,
});

const Start = () => {
  const { userData } = useUserData();
  const navigate = useNavigate();

  const firstTime =
    userData.progression.currentModule === 0 &&
    userData.progression.currentStage === 0;

  const [slide, setSlide] = useState(0);

  const incrementSlide = () => {
    if (firstTime) {
      if (slide === finalSlide) {
        navigate("/city");
      } else {
        setSlide(slide + 1);
      }
    } else {
      navigate("/city");
    }
  };

  return (
    <StyledStart>
      {slide === 0 && (
        <motion.img
          alt="Logo Stichting Lezen & Schrijven"
          src={LezenSchrijvenLogo}
          animate={{
            opacity: [1, 0],
          }}
          transition={transition(1, 0.4)}
          onAnimationComplete={() => setSlide(1)}
        />
      )}
      {slide > 0 && (
        <div>
          <motion.img
            src={`${process.env.PUBLIC_URL}/android-chrome-512x512.png`}
            alt="Logo Life@18"
            variants={{
              hidden: {
                scale: 0,
                maxHeight: "40%",
              },
              big: {
                scale: 1,
              },
              small: {
                maxHeight: "25%",
                scale: 1,
              },
            }}
            initial="hidden"
            animate={slide === 1 ? "big" : "small"}
            transition={transition(0, 0.8)}
            style={{
              transformOrigin: "center bottom",
            }}
          />
          <motion.div
            animate={{
              y: [100, 0],
              opacity: [0, 1],
            }}
            transition={transition(1.2, 0.8)}
            className="header"
          >
            <AnimatePresence mode="wait">
              {slide === 1 && (
                <motion.h1
                  key={slide}
                  aria-live="polite"
                  exit={{
                    opacity: 0,
                    transition: transition(0, 0.4),
                  }}
                >
                  Welkom bij life@18.
                </motion.h1>
              )}
              {slide === 2 &&
                (isMobile ? (
                  <motion.p
                    key={slide}
                    aria-live="polite"
                    animate={{
                      opacity: [0, 1],
                    }}
                    exit={{
                      opacity: 0,
                    }}
                    transition={transition(0, 0.4)}
                  >
                    Voor de beste ervaring speel je de game op je telefoon met
                    het geluid aan.
                    <br />
                    <br />
                    Je kunt de app ook{" "}
                    <NavLink
                      to="/install"
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      installeren
                    </NavLink>{" "}
                    op je telefoon.
                  </motion.p>
                ) : (
                  <motion.p
                    key={slide}
                    aria-live="polite"
                    animate={{
                      opacity: [0, 1],
                    }}
                    exit={{
                      opacity: 0,
                    }}
                    transition={transition(0, 0.4)}
                  >
                    Deze game werkt het beste op je mobiel. Pak je mobiel en
                    open de game. Wil je toch op de computer verder spelen? Klik
                    dan op verder
                  </motion.p>
                ))}
              {slide === 3 && (
                <motion.p
                  key={slide}
                  aria-live="polite"
                  animate={{
                    opacity: [0, 1],
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  transition={transition(0, 0.4)}
                >
                  In deze game leer jij over basiszaken die je moet regelen
                  wanneer je bijna 18 jaar wordt.
                </motion.p>
              )}
              {slide === 4 && (
                <motion.p
                  key={slide}
                  aria-live="polite"
                  animate={{
                    opacity: [0, 1],
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  transition={transition(0, 0.4)}
                >
                  De informatie uit deze game mag nooit worden gezien als
                  (financieel) advies. In het geval je (financieel) advies nodig
                  hebt, raden we je aan om contact op te nemen met een
                  (financieel) adviseur.
                </motion.p>
              )}
            </AnimatePresence>
          </motion.div>
          <div
            style={{
              overflow: "hidden",
              position: "relative",
              width: "100%",
              flexShrink: 0,
            }}
          >
            <Circles
              style={{
                position: "absolute",
                left: "-100%",
                right: "-100%",
                zIndex: -1,
              }}
              animate={{
                y: [170, 0],
              }}
              transition={transition(0, 0.8)}
              gradients={[
                ["light", "main"],
                ["main", "dark"],
              ]}
            ></Circles>
            <div>
              <InvertedButton
                as={motion.button}
                animate={{
                  opacity: [0, 1],
                  y: [100, 0],
                }}
                style={{
                  boxSizing: "border-box",
                  padding: 20,
                  paddingTop: "8%",
                }}
                transition={transition(1.2, 0.8)}
                onClick={incrementSlide}
              >
                {firstTime
                  ? slide === finalSlide
                    ? "Begin het spel"
                    : "Verder"
                  : "Start"}
              </InvertedButton>
            </div>
          </div>
        </div>
      )}
    </StyledStart>
  );
};

export default Start;
