import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ThemeButton from "../../components/ThemeButton/ThemeButton";
import Circles from "../../components/Circles/Circles";
import { AnimatePresence, motion } from "framer-motion";

import Streak from "./img.png";
import ResultConversation from "../../components/ResultConversation/ResultConversation";
import { StemBureauCharacter } from "../../data/Modules/8-Stemmen/StemModule";
import { useAudio } from "../../assets/audio/Audio";
import { useUserData } from "../../data/UserData";
import Help from "../../views/Help/Help";

const StyledVotingInteraction = styled.div`
  height: 100%;
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 20px;

  & > header {
    font-size: 1.3em;
    text-align: center;
    color: white;
    padding-bottom: 70px;
    position: relative;
    font-weight: 700;
    padding-inline: 20px;
  }

  .button {
    padding-inline: 20px;
    box-sizing: border-box;
    margin-top: auto;
  }

  .ballot-container {
    overflow-x: auto;
    flex-shrink: 0;
  }

  .ballot {
    min-width: max-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 1em;
    padding-inline: 1em;
    white-space: nowrap;

    header {
      font-weight: 600;
      text-align: left;
      margin-left: calc(1.1em + 10px);
      font-size: 1.5em;
      margin-bottom: 0.5em;
    }

    .candidate {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 1.2em;

      &:not(:last-child) {
        margin-bottom: 0.5em;
      }

      .checkbox {
        margin-right: 10px;
        width: 1.1em;
        height: 1.1em;
        display: grid;
        place-items: center;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        ::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: black;
          z-index: -1;
        }

        ::after {
          position: absolute;
          content: "";
          width: 90%;
          height: 90%;
          background: white;
          border-radius: 50%;
          z-index: -1;
        }
      }
    }
  }
`;

const VotingInteraction = ({ config }: { config: IVotingInteraction }) => {
  const [step, setStep] = useState(0);
  const [checked, setChecked] = useState([]);
  const [feedback, setFeedback] = useState("");
  const { incrementStage, applyPenalty } = useUserData();
  const { play } = useAudio();
  const voting = config.voting[step];
  const { help } = config;

  useEffect(() => {
    if (step === config.voting.length) {
      incrementStage();
    }
  }, [step, incrementStage, config.voting.length]);

  const {
    ballot: { parties, candidates },
    key: target,
    instructions,
    type,
  } = {
    ...config,
    ...voting,
  };

  const toggleCheck = (candidate: [string, string]) => {
    if (checked.includes(candidate)) {
      setChecked(checked.filter((c) => c !== candidate));
    } else {
      setChecked([...checked, candidate]);
    }
  };

  const closeFeedback = () => {
    setFeedback("");
    setChecked([]);
  };

  const verify = () => {
    let feedback = "";
    if (checked.length === 0) {
      feedback =
        "Je hebt geen kandidaat gekozen. Bij echt stemmen mag je blanco stemmen, maar niet in deze oefening.";
    } else if (checked.length > 1) {
      feedback =
        "Een stem is alleen geldig als er maar 1 vakje is ingekleurd. Probeer het nog eens!";
    } else if (type === "party" && checked[0][1] !== target) {
      feedback =
        "Je stemt op de partij door op een persoon te stemmen die onder deze partij staat. Probeer het nog eens!";
    } else if (type === "candidate") {
      if (checked[0][1] !== target[1]) {
        feedback =
          "Zoek onder partij 'Klomp' naar Z.Nowak. Kruis dat vakje aan. Probeer het nog eens!";
      } else if (checked[0][0] !== target[0]) {
        feedback =
          "Je hebt de goede partij gekozen, maar nog niet de juiste persoon. Zoek de naam Z. Nowak in de rij van partij 'Klomp'.";
      }
    }

    if (feedback) {
      setFeedback(feedback);
      play("wrongAnswer");
      applyPenalty(config.penalty);
      return;
    } else {
      play("pop");
      setChecked([]);
    }

    setStep(step + 1);
  };

  return (
    <StyledVotingInteraction>
      <header>
        <Circles
          style={{
            position: "absolute",
            left: "-50%",
            right: "-50%",
            bottom: 0,
            zIndex: -1,
          }}
          animate={{
            y: ["-100%", "0%"],
          }}
          transition={{
            duration: 0.8,
            ease: "easeOut",
          }}
        ></Circles>
        <AnimatePresence mode="wait">
          <motion.div
            key={step}
            animate={{
              scale: [0, 1],
            }}
            exit={{
              scale: [1, 0],
              transition: {
                delay: 0,
              },
            }}
            transition={{
              duration: 0.4,
              delay: step === 0 ? 0.8 : 0,
              ease: "easeOut",
            }}
            aria-live="polite"
            style={{
              marginRight: help ? 20 + 32 : undefined,
            }}
          >
            {instructions}
          </motion.div>
        </AnimatePresence>
      </header>
      <motion.section
        className="ballot-container"
        animate={{
          opacity: [0, 1],
          y: [50, 0],
        }}
        transition={{
          duration: 0.4,
          delay: 0.8,
          ease: "easeOut",
        }}
      >
        <div className="ballot">
          {parties.map((party) => (
            <div key={party} className="party">
              <header>{party}</header>
              {candidates
                .filter(([, belongsTo]) => belongsTo === party)
                .map((candidate) => (
                  <div
                    role="checkbox"
                    aria-checked={checked.includes(candidate)}
                    className="candidate"
                    key={candidate[0]}
                    onClick={() => toggleCheck(candidate)}
                  >
                    <div className="checkbox">
                      {checked.includes(candidate) && (
                        <img src={Streak} alt="ingevuld" />
                      )}
                    </div>
                    {candidate[0]}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </motion.section>
      <motion.div
        className="button"
        animate={{
          y: ["150%", "0%"],
        }}
        transition={{
          duration: 0.4,
          delay: 0.8,
          ease: "easeOut",
        }}
      >
        <ThemeButton onClick={verify} playSound={false}>
          Controleer
        </ThemeButton>
      </motion.div>
      <AnimatePresence>
        {feedback && (
          <motion.div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <ResultConversation
              text={feedback}
              correct={false}
              right={true}
              character={StemBureauCharacter.Pointing}
              close={closeFeedback}
            ></ResultConversation>
          </motion.div>
        )}
      </AnimatePresence>
      {help && <Help help={help}></Help>}
    </StyledVotingInteraction>
  );
};

export default VotingInteraction;
