import "./Game.scss";

import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import City from "./views/City/City";
import { default as InnerGame } from "./views/Game/Game";
import { AnimatePresence } from "framer-motion";
import ModuleRating, { PlayModule } from "./views/ModuleRating/ModuleRating";
import { useUserData } from "./data/UserData";
import NewAchievement from "./views/Achievements/NewAchievement/NewAchievement";
import Page from "./assets/animations/Page";
import Bank from "./views/Bank/Bank";
import { gameData } from "./data/GameData";
import AchievementShowroom from "./views/Achievements/AchievementShowroom/AchievementShowroom";
import Start from "./views/Start/Start";
import Install from "./views/Install/Install";
import Settings from "./views/Settings/Settings";
import ServiceWorkerUpdate from "./util/ServiceWorkerUpdate";
import { defaultUserData } from "./data/defaultUserData";
import Device from "./views/Device/Device";
import Poster from "./views/Poster/Poster";
import Oops from "./views/Oops/Oops";
import Library from "./views/Library/Library";
import DonorModule from "./data/Modules/3-DonorModule/DonorModule";

const donorIndex = gameData.modules.indexOf(DonorModule);
const donorSetter = gameData.modules
  .slice(0, donorIndex)
  .reduce((sum, module) => sum + module.stages.length, 0);

const devMode = process.env.NODE_ENV === "development";
const basename = document.querySelector("base")?.getAttribute("href") ?? "/";

export const routes = [
  {
    path: "/start",
    Component: Start,
  },
  {
    path: "/install",
    Component: Install,
  },
  {
    path: "/settings",
    Component: () => {
      return <Settings alwaysOpen></Settings>;
    },
  },
  {
    path: "/city",
    Component: City,
    navBar: "City",
  },
  {
    path: "/bank",
    Component: Bank,
    // navBar: "Bank",
  },
  {
    path: "/poster",
    Component: Poster,
  },
  {
    path: "/new-achievement",
    Component: NewAchievement,
  },
  {
    path: "/achievements",
    Component: AchievementShowroom,
  },
  {
    path: "/library",
    Component: Library,
  },
  {
    path: "/rating",
    Component: ModuleRating,
    // navBar: "Rating",
  },
  {
    path: "/play-module",
    Component: PlayModule,
    // navBar: "Rating",
  },
  {
    path: "/game",
    Component: InnerGame,
    navBar: "Game",
  },
  {
    path: "/device",
    Component: Device,
  },
  {
    path: "/clear",
    Component: () => {
      const { setUserData } = useUserData();

      useEffect(() => {
        setUserData(defaultUserData);
      }, [setUserData]);

      return (
        <NavLink
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          to="/start"
        >
          Verder
        </NavLink>
      );
    },
  },
  {
    path: "/set/:value",
    Component: () => {
      const { setUserData } = useUserData();
      const { value } = useParams();
      const val = Number(value);

      const allStages = gameData.modules.reduce((acc, module, i) => {
        return acc.concat(module.stages.map((stage, j) => [i, j]));
      }, []);

      const stage = allStages[val];

      useEffect(() => {
        setUserData((userData) => ({
          ...userData,
          progression: {
            ...userData.progression,
            currentModule: stage[0],
            currentStage: stage[1],
          },
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
        <NavLink
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          to="/city"
        >
          Verder
        </NavLink>
      );
    },
  },
  {
    path: "/donor",
    Component: () => {
      const navigate = useNavigate();
      useEffect(() => {
        navigate(`/set/${donorSetter}`);
      }, [navigate]);

      return <></>;
    },
  },
  {
    path: "/oops",
    Component: Oops,
  },
  {
    path: "/demo",
    Component: () => {
      const { setUserData } = useUserData();

      const lastModule = gameData.modules.length - 1;

      useEffect(() => {
        setUserData({
          ...defaultUserData,
          demo: true,
          progression: {
            ...defaultUserData.progression,
            currentModule: lastModule,
            currentStage: 0,
            checkpoints: [],
          },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
        <NavLink
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          to="/start"
        >
          Start demo
        </NavLink>
      );
    },
  },
  {
    path: "/",
    Component: Start,
  },
  {
    path: "*",
    Component: () => <Navigate to="/city" />,
  },
];

const Game = () => {
  const location = useLocation();

  const { userData } = useUserData();
  const { moduleRatings } = userData.progression;

  const navigate = useNavigate();

  // we add a new module so we need to add a new module rating
  useEffect(() => {
    if (moduleRatings.length !== gameData.modules.length) {
      navigate("/oops");
    }
  }, [moduleRatings.length, navigate]);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Page>
                <route.Component />
              </Page>
            }
          />
        ))}
      </Routes>
    </AnimatePresence>
  );
};

const GameRouter = () => {
  const [fullScreen, setFullScreen] = useState(true);

  return (
    <BrowserRouter basename={basename}>
      {devMode && (
        <span
          aria-hidden
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1000,
            background: "white",
          }}
          onClick={() => setFullScreen(!fullScreen)}
        >
          Full screen
        </span>
      )}

      <div className={`Game ${fullScreen ? "full-screen" : ""}`}>
        {devMode && (
          <NavBar
            style={{
              left: -1,
              width: "calc(100% + 2px)",
              position: fullScreen ? "static" : "absolute",
              transform: fullScreen ? "none" : "translateY(-100%)",
            }}
          ></NavBar>
        )}
        <div className="main">
          <Game />
        </div>
        <ServiceWorkerUpdate />
      </div>
    </BrowserRouter>
  );
};

export default GameRouter;
