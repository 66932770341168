import BackgroundImg from "./images/stagevergoeding-inside_1.svg";
import Noah from "../../../assets/characters/Noah/Noah";
import StageVergoedingCharacter from "./images/character/StageVergoedingCharacter";

import EventImg1 from "./images/event/event2-1.svg";
import EventImg2 from "./images/event/event2-2.svg";
import EventImg3 from "./images/event/event2-3.1.svg";
import EventImg4 from "./images/event/event2-3.2.svg";
import { Variable } from "../../variables";
import { getVoiceLineGenerator } from "../../../util/Voice";

const voice = getVoiceLineGenerator(
  [
    "20230713-Noah-Kristel-10.27-08.mp3",
    "20230713-Noah-Kristel-10.29-09.mp3",
    "Noah - Kristel-09.11-34.mp3",
    "Noah - Kristel-09.13-35.mp3",
    "Stagevergoeding - JW-01.1-01.mp3",
    "Stagevergoeding - JW-01_bip-02.mp3",
    "Stagevergoeding - JW-01.4-03.mp3",
    "Stagevergoeding - JW-03.3-04.mp3",
    "Stagevergoeding - JW-01.7-05.mp3",
    "Stagevergoeding - JW-01.9-06.mp3",
    "Noah - Kristel-09.15-36.mp3",
  ].map((f) => require(`./voice/${f}`))
);

const options = [
  "20230713-Noah-Kristel-10.12-10.mp3",
  "20230713-Noah-Kristel-16.3-11.mp3",
  "20230713-Noah-Kristel-10.14-12.mp3",
  "20230713-Noah-Kristel-10.15-13.mp3",
].map((f) => require(`./voice/${f}`));

const StageVergoedingModule: Module = {
  name: "Stagevergoeding",
  resumeBuilding: "stage",
  stages: [
    {
      type: "event",
      redirect: "/game",
      buttonText: "Verder",
      steps: [
        {
          header: "Pauze",
          text: "Het is de volgende dag. Je bent op school en het is pauze. Je staat buiten met je vrienden.",
          voiceLine: voice(),
          image: EventImg1,
        },
        {
          header: "Oh nee!",
          text: "Je maakt een vlog. Terwijl je rondloopt, sla je iemands mobiel uit de handen. Mobiel kapot!",
          image: EventImg2,
          voiceLine: voice(),
        },
        {
          header: "Aansprakelijkheids\xADverzekering",
          text: `{{ hasAV = ${Variable(
            "aansprakelijkheidsVerzekering"
          )} > 0}}{{= hasAV ? 'Gelukkig heb je een aansprakelijkheids\xADvezekering.' : 'Je hebt gekozen om geen aansprakelijkheids\xADverzekering te nemen.' }}`,
          image: `{{= hasAV ? '${EventImg3}' : '${EventImg4}' }}`,
          voiceLine: () => options[window["hasAV"] ? 0 : 1],
        },
        {
          header: "Aansprakelijkheids\xADverzekering",
          text: "{{= hasAV ? 'Daarom hoef je nu niets zelf te betalen.' : 'Daarom zal je de kosten van een nieuwe mobiel zelf moeten betalen.' }}",
          image: `{{= hasAV ? '${EventImg3}' : '${EventImg4}' }}`,
          voiceLine: () => options[window["hasAV"] ? 2 : 3],
        },
      ],
      results: [
        {
          type: "bank",
          description: "Kapotte telefoon",
          amount: "{{= hasAV ? 0 : -500 }}",
          key: "telefoon",
        },
      ],
    },
    {
      type: "conversation",
      inCity: true,
      characterImage: Noah.Default,
      buttonText: "Verder",
      steps: [
        {
          text: "Tijdens de pauze hoorde ik mensen praten over hun *stagevergoeding*.",
          voiceLine: voice(),
        },
        {
          text: "Ik weet eigenlijk niet wat dat is. Heb ik daar ook recht op? Laten we het uitzoeken!",
          voiceLine: voice(),
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Laten we uitzoeken of ik recht heb op *stagevergoeding*.",
          },
        },
      ],
    },
    {
      type: "conversation",
      buttonText: "Verder",
      right: true,
      backgroundImage: BackgroundImg,
      replayHere: true,
      steps: [
        {
          characterImage: StageVergoedingCharacter.Default,
          text: "Hee! Goed dat je komt uitzoeken of je recht hebt op *stagevergoeding*.",
          voiceLine: voice(),
        },
        {
          characterImage: StageVergoedingCharacter.Explaining,
          voiceLine: voice(),
          text: "Hier is vaak onduidelijkheid over. Beantwoord de volgende vragen. Dan weet jij of je er recht op hebt!",
        },
      ],
    },
    {
      type: "trueFalse",
      answers: ["Niet waar", "Waar"],
      penalty: 15,
      correctCharacter: StageVergoedingCharacter.Explaining,
      incorrectCharacter: StageVergoedingCharacter.Default,
      questions: [
        {
          question: "Het is wettelijk verplicht een stagevergoeding te geven.",
          answer: 0,
          correctText:
            "Klopt! Je *stagebedrijf* mag zelf bepalen of je een *stagevergoeding* krijgt.",
          incorrectText:
            "Dat is niet het goede antwoord. Je *stagebedrijf* is niet verplicht je een *stagevergoeding* te geven.",
        },
        {
          question:
            "Je moet een arbeidsovereenkomst tekenen als je stage gaat lopen.",
          answer: 0,
          correctText:
            "Klopt! Je ondertekent een *stageovereenkomst*. Dat is iets anders.",
          incorrectText:
            "Dat is niet het goede antwoord. Je ondertekent een *stageovereenkomst*. Dat is iets anders.",
        },
        {
          question: "De minimum stagevergoeding is wettelijk bepaald.",
          answer: 0,
          correctText:
            "Klopt! Het bedrijf waar je stage loopt, mag zelf weten of ze een *stagevergoeding* geven. Ook mag het bedrijf zelf weten hoeveel *stagevergoeding* je krijgt. ",
          incorrectText:
            "Dat is niet het goede antwoord. Het bedrijf waar je stage loopt mag zelf weten of en hoeveel *stagevergoeding* ze geven.",
        },
        {
          question:
            "Bij 5 dagen stage lopen, krijgt een mbo'er gemiddeld €227 stagevergoeding.",
          answer: 1,
          correctText: "Klopt! Als je 4 dagen stage loopt, is dat €182.",
          incorrectText:
            "Dat is niet het goede antwoord. Als mbo'er krijg je gemiddeld €227 *stagevergoeding* als je 5 dagen stage loopt.",
        },
        {
          question:
            "Ik hoef geen belasting te betalen over mijn stagevergoeding.",
          answer: 0,
          correctText:
            "Klopt! Een *stagevergoeding* wordt gezien als *inkomen*. Daar moet je *belasting* over betalen. Je kunt wel *loonheffingskorting* aanvragen.",
          incorrectText:
            "Dat is niet het goede antwoord. Een *stagevergoeding* wordt gezien als *inkomen*. Daar moet je *belasting* over betalen. Je kunt wel *loonheffingskorting* aanvragen.",
        },
        {
          question: "Mijn reiskosten worden vergoed door het stagebedrijf.",
          answer: 1,
          correctText:
            "Klopt! Vanaf 2023 is een werkgever verplicht de kosten die een student maakt voor stage te vergoeden. Dit heet ook wel de *onkostenvergoeding*.",
          incorrectText:
            "Dat is niet het goede antwoord. Vanaf 2023 is een werkgever verplicht de kosten die een student maakt voor stage te vergoeden. Dit heet ook wel de *onkostenvergoeding*.",
        },
      ],
    },
    {
      type: "redirect",
      redirect: "/rating",
    },
    {
      type: "conversation",
      buttonText: "Verder",
      right: true,
      characterImage: StageVergoedingCharacter.Default,
      backgroundImage: BackgroundImg,
      steps: [
        {
          text: "Je weet nu dat je niet altijd recht hebt op een *stagevergoeding*.",
          voiceLine: voice(),
        },
        {
          text: "Vraag dus altijd bij een bedrijf na. Misschien hebben ze een *stagevergoeding* en/of een *onkostenvergoeding*.",
          characterImage: StageVergoedingCharacter.Explaining,
          voiceLine: voice(),
        },
        {
          text: "Hoeveel stagevergoeding je krijgt, mag het stagebedrijf zelf bepalen.",
          characterImage: StageVergoedingCharacter.Explaining,
          voiceLine: voice(),
        },
        {
          text: "Je moet wel *belasting* betalen over je *stagevergoeding*. Maar je kunt ook *loonheffingskorting* aanvragen.",
          voiceLine: voice(),
        },
        {
          right: false,
          characterImage: Noah.Default,
          text: "Goed om dit allemaal te weten! Laten we weer terug gaan naar de stad.",
          buttonText: "Naar de stad",
          voiceLine: voice(),
        },
      ],
    },
  ],
};

export default StageVergoedingModule;
