import "./NavBar.scss";
import React, { ComponentProps } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../GameRouter";

const StyledNavBar = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.main};
  height: 50px;
  color: white;
  z-index: 100;

  & > a {
    flex: 1;
    text-align: center;
    padding: 10px;
    display: grid;
    place-items: center;

    &.active {
      background-color: ${({ theme }) => theme.light};
    }
  }
`;

const NavBar = (props: ComponentProps<typeof StyledNavBar>) => (
  <StyledNavBar aria-hidden {...props}>
    {routes.map(
      (route) =>
        route.navBar && (
          <NavLink to={route.path} key={route.path}>
            {route.navBar}
          </NavLink>
        )
    )}
  </StyledNavBar>
);

export default NavBar;
