import StufiDefaultImg from "./char-stufi-default.svg";
import StufiExplainingImg from "./char-stufi-explaining.svg";
import StufiPhoneInHandImg from "./char-stufi-phone-in-hand.svg";
import StufiSweatImg from "./char-stufi-sweat.svg";
import StufiBookInHandImg from "./char-stufi-book-in-hand.svg";

const Stufi = {
  Default: StufiDefaultImg,
  Explaining: StufiExplainingImg,
  PhoneInHand: StufiPhoneInHandImg,
  Sweat: StufiSweatImg,
  BookInHand: StufiBookInHandImg,
};

export default Stufi;
