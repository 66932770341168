import React, { Fragment } from "react";
import styled from "styled-components";
import { useDraggable } from "react-use-draggable-scroll";
import { isMobile } from "react-device-detect";

import Logo from "./BankLogo.svg";
import { motion, MotionConfig } from "framer-motion";
import IconButton from "../../components/IconButton/IconButton";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../data/UserData";
import Help from "../Help/Help";
import Noah from "../../assets/characters/Noah/Noah";
import ThemeButton from "../../components/ThemeButton/ThemeButton";
import { euroFormat } from "../../util/EuroFormat";

import BankVoiceLine1 from "./Noah-Kristel-01.29-12.mp3";
import BankVoiceLine2 from "./Noah-Kristel-04.3-13.mp3";

const StyledBank = styled.div`
  height: 100%;
  padding: 20px;
  position: relative;
  color: ${(props) => props.theme.dark};

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;

  .icon-button {
    position: absolute;
    top: 20px;

    &.back {
      left: 20px;
    }

    &.help {
      right: 20px;
    }
  }

  .logo {
    margin-top: 10px;
  }

  .header {
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 10px;
  }

  .table {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0;

    font-size: 0.75em;

    & > * {
      width: 100%;
    }

    .table-inner {
      display: grid;
      grid-template-columns: auto 25%;
      padding: 11px;
      border-radius: 3px;
    }

    .heading {
      background: #eeeeee;
      font-weight: 700;
    }

    .body {
      overflow-y: auto;
      flex: 1 1 40px;
      align-content: flex-start;

      //  Hide scrollbar
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      padding: 0 11px;

      .desc {
        white-space: pre-line;
      }

      & > div {
        position: relative;
        padding: 15px 0;
        border-bottom: 1px solid ${(props) => props.theme.superLight};

        &:nth-child(odd) {
          padding-right: 30px;
        }

        &:nth-child(even) {
          &::before {
            position: absolute;
            transform: translate(-19px, -4px);
            font-weight: 900;
            font-size: 1.5em;
          }

          &.up::before {
            content: "+";
            color: ${(props) => props.theme.green.dark};
          }

          &.down::before {
            content: "-";
            color: ${(props) => props.theme.red.dark};
          }
        }
      }
    }

    .total {
      margin-top: auto;
      color: white;
      background: ${(props) => props.theme.main};
      font-weight: 700;
    }
  }
`;

const Bank = () => {
  const navigate = useNavigate();
  let { userData } = useUserData();

  const module = userData.progression.currentModule;
  const stage = userData.progression.currentStage;

  const firstTime = module === 1 && stage === 0;

  const body = React.useRef<HTMLDivElement>(null);
  let { events }: { events: any } = useDraggable(body);
  events = isMobile ? {} : events;

  const bankUpdates = [...userData.bankUpdates]
    .reverse()
    .filter((update) => update.amount !== 0);

  return (
    <StyledBank>
      <MotionConfig
        transition={{
          ease: "easeOut",
          duration: 0.4,
        }}
      >
        <motion.div
          animate={{ scale: [0, 1] }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img className="logo" alt="Logo bank" src={Logo} />
          <div className="header">Bankrekening</div>
        </motion.div>
        <motion.div className="table" animate={{ x: ["-100%", "0%"] }}>
          <div className="heading table-inner">
            <div>Omschrijving</div>
            <div>Bedrag</div>
          </div>
          <div className="body table-inner" ref={body} {...events}>
            {bankUpdates.map((update, i) => (
              <Fragment key={i}>
                <div className="desc">{update.description}</div>
                <div
                  role="listitem"
                  aria-label={`${
                    update.amount < 0 ? "min" : "plus"
                  } ${euroFormat.format(
                    update.amount < 0 ? -update.amount : update.amount
                  )}`}
                  className={update.amount < 0 ? "down" : "up"}
                >
                  {euroFormat.format(
                    update.amount < 0 ? -update.amount : update.amount
                  )}
                </div>
              </Fragment>
            ))}
          </div>
          <div className="total table-inner">
            <div>Totaal</div>
            <div>
              {euroFormat.format(
                bankUpdates
                  .map((update) => update.amount)
                  .reduce((a, b) => a + b, 0)
              )}
            </div>
          </div>
        </motion.div>
        <ThemeButton
          onClick={() => navigate("/city")}
          as={motion.button}
          animate={{
            y: ["100%", "0%"],
          }}
        >
          Naar de stad
        </ThemeButton>
      </MotionConfig>
      <IconButton
        onClick={() => navigate("/city")}
        className="icon-button back"
        icon="Back"
      />
      <Help
        help={{
          text: firstTime
            ? [
                "Hier zie je tijdens het spel jouw inkomsten (+) en uitgaven (-). Gebruik dit overzicht om keuzes te maken.",
                "Gelukkig heb ik alvast geld voor mijn verjaardag gekregen. En komt er elke maand geld binnen van mijn bijbaan!",
              ]
            : "Hier zie je tijdens het spel jouw inkomsten (+) en uitgaven (-). Gebruik dit overzicht om keuzes te maken.",
          characterImage: Noah.Default,
          voiceLine: [BankVoiceLine1, BankVoiceLine2],
        }}
        open={firstTime}
      ></Help>
    </StyledBank>
  );
};

export default Bank;
