import Noah from "../../../assets/characters/Noah/Noah";
import StufiCharacter from "./images/Stufi/StufiCharacter";

import BackgroundImg from "./images/stufi-inside.svg";
import { euroFormat } from "../../../util/EuroFormat";
import { Choices, Variable } from "../../variables";
import { getVoiceLineGenerator } from "../../../util/Voice";

const voice = getVoiceLineGenerator(
  [
    "Noah - Kristel-09.21-39.mp3",
    "Noah - Kristel-09.23-40.mp3",
    "Noah - Kristel-09.25-41.mp3",
    "Noah - Kristel-09.27-42.mp3",
    "Studiefinanciering - Max-04.3-01.mp3",
    "Studiefinanciering - Max-01.2-02.mp3",
    "Studiefinanciering - Max-01.4-03.mp3",
    "Studiefinanciering - Max-01.6-04.mp3",
    "20230713-Stufi-Max-07.23-05.mp3",
    "Studiefinanciering - Max-01.10-06.mp3",
    "Studiefinanciering - Max-01.12-07.mp3",
    "Studiefinanciering - Max-01.14-08.mp3",
    "Studiefinanciering - Max-02.1-09.mp3",
    "20230713-Stufi-Max-07.21-04.mp3",
    "Studiefinanciering - Max-02.5-11.mp3",
    "Noah - Kristel-13.1-43.mp3",
    "Noah - Kristel-13.3-44.mp3",
    "Studiefinanciering - Max-03.1-12.mp3",
    "20230713-Stufi-Max-07.24-06.mp3",
  ].map((f) => require(`./voice/${f}`))
);

const stufiChoice = [
  ["", "Noah - Kristel-13.9-47.mp3", "Noah - Kristel-13.11-48.mp3"].map(
    (f) => f && require(`./voice/${f}`)
  ),
  [
    "20230713-Stufi-Max-07.20-03.mp3",
    "Noah - Kristel-13.5-45.mp3",
    "Noah - Kristel-13.7-46.mp3",
  ].map((f) => f && require(`./voice/${f}`)),
];

const StufiModule: Module = {
  name: "StufiModule",
  resumeBuilding: "stufi",
  stages: [
    {
      type: "conversation",
      inCity: true,
      buttonText: "Verder",
      characterImage: Noah.Default,
      steps: [
        {
          characterImage: Noah.Sweat,
          text: "Het nieuwe studiejaar begint weer bijna. Dan moet ik weer *lesgeld* betalen...",
          voiceLine: voice(),
        },
        {
          text: "De overheid bepaalt hoeveel lesgeld je betaalt. Dit verschilt per jaar.",
          voiceLine: voice(),
        },
        {
          text: "Dat is veel geld. Maar gelukkig is er *studiefinanciering*! Dit noemen we ook wel *stufi*.",
          voiceLine: voice(),
        },
        {
          text: "Laten we naar het stufi-gebouw gaan om meer over *stufi* te leren.",
          voiceLine: voice(),
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Laten we naar het *Stufi*-gebouw gaan om daar meer over te leren.",
          },
        },
      ],
    },
    {
      type: "conversation",
      buttonText: "Verder",
      backgroundImage: BackgroundImg,
      right: true,
      replayHere: true,
      steps: [
        {
          characterImage: StufiCharacter.Default,
          text: "Slim om *stufi* aan te vragen!",
          voiceLine: voice(),
        },
        {
          characterImage: StufiCharacter.BookInHand,
          voiceLine: voice(),
          text: "Naar school gaan kost geld. Met je *stufi* kun je dit betalen.",
        },
        {
          characterImage: StufiCharacter.Explaining,
          voiceLine: voice(),
          text: "Als je mbo 1 of 2 doet, zijn de *basisbeurs, aanvullende beurs en het studentenreisproduct* altijd een gift. Ook als je de studie niet afmaakt.",
        },
        {
          characterImage: StufiCharacter.Explaining,
          voiceLine: voice(),
          text: "Bij mbo 3, mbo 4, hbo of universiteit is dat anders.",
        },
        {
          characterImage: StufiCharacter.Default,
          voiceLine: voice(),
          text: "Doe je mbo 3 of 4, dan zijn de *basisbeurs, aanvullende beurs en het studentenreisproduct* een lening. Dit heet een *prestatiebeurs*. Deze lening kan een gift worden. Als je het diploma haalt *binnen 10 jaar*. ",
        },
        {
          characterImage: StufiCharacter.Sweat,
          voiceLine: voice(),
          text: "Je kan extra geld *bijlenen*. Dat moet je altijd *terugbetalen*. Ook als je mbo 1 of 2 doet.",
        },
        {
          characterImage: StufiCharacter.Explaining,
          voiceLine: voice(),
          text: "Niet iedereen krijgt evenveel *stufi*. Hoe dat kan, gaan we nu ontdekken.",
        },
      ],
    },
    {
      type: "sliderInteraction",
      button: "Verder",
      text: "Hoeveel Stufi krijg jij?",
      inputs: [
        {
          type: "radio",
          name: "Ik ben 18 jaar of ouder.",
          on: 1,
          off: 0,
        },
        {
          type: "radio",
          name: "Ik woon op mezelf.",
          off: 103.78,
          on: 338.68,
        },
      ],
      outputs: [
        {
          name: "Stufi per maand",
          function(a, b) {
            return a * b;
          },
          formatter: euroFormat.format,
          saveVariable: "stufi",
        },
      ],
    },
    {
      type: "conversation",
      backgroundImage: BackgroundImg,
      buttonText: "Verder",
      characterImage: StufiCharacter.Explaining,
      right: true,
      steps: [
        {
          text: "Sommige studenten hebben recht op een *aanvullende beurs*. Dit is geld wat jij extra bij jouw *stufi* krijgt. Dus geen lening!",
          voiceLine: voice(),
        },
        {
          text: "Dit bedrag hangt af van het inkomen en het aantal kinderen van je ouders.",
          voiceLine: voice(),
        },
        {
          text: "De *aanvullende beurs* moet je ook zelf aanvragen.",
          voiceLine: voice(),
        },
        {
          text: "Je kunt ook extra *lenen*. Maar let op: Dit moet je altijd met *rente* terugbetalen! Je betaalt dus altijd meer terug, dan dat je hebt geleend.",
          voiceLine: voice(),
          characterImage: StufiCharacter.Sweat,
        },
      ],
    },
    {
      type: "sliderInteraction",
      // button: "Verder",
      text: "Je kun ook extra geld lenen. Dit moet je altijd na jouw studie terugbetalen. Hoeveel wil jij bijlenen?",
      button: "Verder",
      inputs: [
        {
          type: "range",
          name: "Extra lenen",
          label: "Extra lenen",
          min: 0,
          max: 226.08,
          formatter: euroFormat.format,
        },
        {
          type: "hidden",
          name: "Stufi",
          value: `{{= ${Variable("stufi")} }}`,
        },
      ],
      outputs: [
        {
          name: "Eindbedrag per maand",
          function(a, b) {
            a = Number(a.toFixed(2));
            return a + b;
          },
          formatter: euroFormat.format,
        },
        {
          hidden: true,
          name: "StufiLening",
          function(a) {
            return Number(a.toFixed(2));
          },
          saveVariable: "stufiLening",
        },
      ],
    },
    {
      type: "conversation",
      backgroundImage: BackgroundImg,
      buttonText: "Verder",
      characterImage: Noah.Sweat,
      steps: [
        {
          text: "Pff, hoeveel *stufi* je krijgt, hangt van een hoop dingen af.",
          voiceLine: voice(),
        },
        {
          text: "Ik kom nog best veel moeilijke woorden tegen. Laten we die eens beter bekijken.",
          voiceLine: voice(),
        },
      ],
    },
    {
      type: "bubblePopperInteraction",
      penalty: 5,
      answers: [
        "lesgeld",
        "Dienst Uitvoering Onderwijs (DUO)",
        "basisbeurs",
        "studiefinanciering",
      ],
      questions: [
        {
          question: "Geld om een deel van je studiekosten te betalen",
          answers: ["studiefinanciering"],
          canSkip: true,
        },
        {
          question: "Bij deze organisatie vraag je stufi aan",
          answers: ["Dienst Uitvoering Onderwijs (DUO)"],
          canSkip: true,
        },
        {
          question: "Dit betaal je elk jaar (of elke maand) voor je studie",
          answers: ["lesgeld"],
          canSkip: true,
        },
        {
          question: "De stufi waar iedereen recht op heeft.",
          answers: ["basisbeurs"],
          canSkip: true,
        },
      ],
    },
    {
      type: "bubblePopperInteraction",
      penalty: 5,
      answers: ["aanvullende beurs", "gift", "prestatiebeurs", "rente"],
      questions: [
        {
          question:
            "De basisbeurs, aanvullende beurs en reisproduct wat je krijgt als je mbo 3 of 4 doet. Dit wordt een gift als je binnen 10 jaar je diploma haalt.",
          answers: ["prestatiebeurs"],
          canSkip: true,
        },
        {
          question:
            "Extra geld dat je kunt krijgen. Dit hangt af van het inkomen en situatie van jouw ouders.",
          answers: ["aanvullende beurs"],
          canSkip: true,
        },
        {
          question: "Als je je stufi niet terug hoeft te betalen.",
          answers: ["gift"],
          canSkip: true,
        },
        {
          question: "Een vergoeding die je betaalt voor het lenen van geld",
          answers: ["rente"],
          canSkip: true,
        },
      ],
    },
    {
      type: "redirect",
      redirect: "/rating",
    },
    {
      type: "conversation",
      buttonText: "Verder",
      backgroundImage: BackgroundImg,
      right: true,
      steps: [
        {
          characterImage: StufiCharacter.Default,
          text: "Nu weet je wat stufi is. En weet je hoeveel je kunt krijgen.",
          voiceLine: voice(),
        },
        {
          characterImage: StufiCharacter.BookInHand,
          text: "Via de website van *DUO* kun je *stufi* aanvragen. Wat wil jij?",
          voiceLine: voice(),
        },
      ],
    },
    {
      type: "choice",
      header: "Stufi aanvragen",
      text: "Wil je stufi aanvragen?",
      choices: [
        {
          display: "Ja",
          saveVariable: [Choices.stufi],
          value: [
            `{{= ${Variable("stufi")} > 0 ? ${Variable("stufi")} : 99.94 }}`,
          ],
        },
        {
          display: "Nee",
          value: [0, 0],
          saveVariable: [Choices.stufi, Choices.stufiLening],
        },
      ],
    },
    {
      type: "conversation",
      buttonText: "Verder",
      backgroundImage: BackgroundImg,
      characterImage: Noah.Default,
      steps: [
        {
          characterImage: StufiCharacter.BookInHand,
          right: true,
          text: `{{ X = ${Variable("stufi")} + ${Variable(
            "stufiLening"
          )}; hasStufi = X > 0; Y = X.toFixed(2).replace('.',',')}}{{= X > 0 ? 'Je hebt je *stufi* aangevraagd! Je krijgt elke maand €' + Y + ' op je bankrekening gestort.' : 'Je hebt er voor gekozen geen *stufi* aan te vragen.' }}`,
          voiceLine: () => stufiChoice[window["hasStufi"] ? 1 : 0][0],
        },
        {
          text: "{{= X > 0 ? 'Als je *stufi* aanvraagt, duurt het 3 maanden voor je het krijgt.' : 'Je mag er voor kiezen geen *stufi* aan te vragen.' }}",
          voiceLine: () => stufiChoice[window["hasStufi"] ? 1 : 0][1],
        },
        {
          text: "{{= X > 0 ? 'Dus het is belangrijk om op tijd *stufi* aan te vragen. Dat kan al voordat je 18 jaar bent.' : 'Je kunt dit altijd later nog aanpassen.' }}",
          voiceLine: () => stufiChoice[window["hasStufi"] ? 1 : 0][2],
        },
      ],
      results: [
        {
          type: "bank",
          amount: `{{= ${Variable("stufi")} + ${Variable("stufiLening")}}}`,
          description: "Stufi",
          key: "stufi",
          monthly: true,
        },
      ],
    },
    {
      type: "trueFalse",
      help: {
        characterImage: Noah.SlightlySmiling,
        buttonText: "Terug",
        text: "Om je *inkomen* te berekenen, moet je alles bij elkaar optellen wat op je bankrekening binnenkomt. Wat krijg je als je de bedragen optelt?",
      },
      questions: [
        {
          question: `{{ stufi = ${Variable("stufi")} + ${Variable(
            "stufiLening"
          )}; zorgtoeslag = ${Variable(
            "zorgToeslag"
          )}; answer = stufi + zorgtoeslag + 200; }}Je verdient €200 met je bijbaan{{= zorgtoeslag > 0 ? ', en je krijgt €' + zorgtoeslag + ' zorgtoeslag' : '' }}. Je krijgt nu ook €{{= stufi.toFixed(2).replace('.',',') }} stufi. Wat is je inkomen iedere maand?`,
          answers: [
            "€{{= (answer - 10).toFixed(2).replace('.',',')}}",
            `€{{= answer.toFixed(2).replace('.',',') }}`,
            "€{{= (answer + 100).toFixed(2).replace('.',',')}}",
          ],
          answer: 1,
          incorrectText:
            "Dit is niet goed. Om je *inkomen* te berekenen, moet je de bedragen bij elkaar optellen.",
        },
      ],
    },
  ],
};

export default StufiModule;
