import React, { ComponentPropsWithRef, useEffect, useRef } from "react";

import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import Conversation, {
  ConversationProps,
} from "../../interactions/Conversation/Conversation";
import Noah from "../../assets/characters/Noah/Noah";

interface Props {
  text: string;
  correct: boolean;
  character?: string;
  close?: () => void;
}

const StyledResultConversation = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: left;

  .conversation {
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.66),
      ${(props) =>
        props.correct ? props.theme.green.light : props.theme.red.light}
    );
    z-index: 1;
  }
`;

const ResultConversation = ({
  text,
  close,
  correct,
  character,
  ...props
}: Props & ComponentPropsWithRef<typeof StyledResultConversation>) => {
  const help: ConversationProps["config"] = {
    type: "help",
    steps: [text],
    characterImage: character ?? (correct ? Noah.ThumbsUp : Noah.Sweat),
    buttonText: "Verder",
    right: character && !Object.values(Noah).includes(character),
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <StyledResultConversation
      ref={ref}
      tabIndex={0}
      correct={correct}
      {...props}
    >
      <div className="conversation">
        <AnimatePresence initial={false}>
          <Conversation
            close={close}
            invertedButton
            config={help}
          ></Conversation>
        </AnimatePresence>
      </div>
    </StyledResultConversation>
  );
};

export default ResultConversation;
