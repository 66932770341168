import { gameData } from "../GameData";
import StemModule from "../Modules/8-Stemmen/StemModule";

type Migration = {
  date: Date;
  description: string;
  execute: (userData: UserData) => void;
};

export const migrations: Migration[] = [
  {
    date: new Date("2023-10-23"),
    description:
      "Add StemModule -- Update progression -- add checkpoints -- add modules completed",
    execute: (userData: UserData & any) => {
      const newModuleIndex = gameData.modules.indexOf(StemModule);
      console.debug("newModuleIndex", newModuleIndex);
      console.debug(
        "old",
        userData.progression.currentModule,
        userData.progression.furthest,
        userData.progression.moduleRatings,
        userData.progression.previousRatings
      );
      // players should stay in their current module
      if (userData.progression.currentModule >= newModuleIndex) {
        userData.progression.currentModule++;
      }
      if (userData.progression.furthest.module >= newModuleIndex) {
        userData.progression.furthest.module++;
      }
      // rework furthest into checkpoints
      userData.progression.checkpoints = [];
      if (
        userData.progression.furthest.module >
        userData.progression.currentModule
      ) {
        userData.progression.checkpoints.push(
          userData.progression.furthest.module
        );
      }
      userData.progression.moduleRatings.splice(newModuleIndex, 0, 100);
      userData.progression.previousRatings.splice(newModuleIndex, 0, []);
      userData.progression.modulesCompleted = [];
      for (let i = 0; i < userData.progression.furthest.module; i++) {
        if (i === newModuleIndex) continue;
        userData.progression.modulesCompleted.push(i);
      }
      console.debug(
        "new",
        userData.progression.currentModule,
        userData.progression.checkpoints,
        userData.progression.moduleRatings,
        userData.progression.previousRatings
      );
    },
  },
];

migrations.sort((a, b) => {
  return a.date.getTime() - a.date.getTime();
});

export function executeMigrations(userData: UserData) {
  const migrationsExecuted = userData.migrationsExecuted || [];
  for (const migration of migrations) {
    const name = migration.date.toISOString();
    if (!migrationsExecuted.includes(name)) {
      console.group("executing migration", name);
      migration.execute(userData);
      migrationsExecuted.push(name);
      console.groupEnd();
    }
  }
  userData.migrationsExecuted = migrationsExecuted;
}
