import RaccoonAnalytics from "@raccoon-serious-games/raccoon-analytics";
import { parseUserAgent } from "react-device-detect";
import { gameData } from "../data/GameData";
import merge from "lodash/merge";

// check with the server if we should enable analytics
export const analyticsEnabled = fetch(
  process.env.PUBLIC_URL + "/analytics.json"
)
  .then((res) => res.json())
  .then(({ enabled, devToken }) => {
    if (enabled) {
      RaccoonAnalytics.setApiKey(
        devToken
          ? process.env.REACT_APP_ANALYTICS_TOKEN_DEV
          : process.env.REACT_APP_ANALYTICS_TOKEN
      );
      // Explicit initialization for the websocket
      RaccoonAnalytics.initialize();
    }
    console.info(`Analytics ${enabled ? "enabled" : "disabled"}.`);
    return enabled;
  });

analyticsEnabled.then((enabled) => {
  if (!enabled) return;
  const device = parseUserAgent(navigator.userAgent);
  const OS = device.os;
  const browser = device.browser;
  const model = device.device;

  const osString = `${OS.name} ${OS.version}`;
  const browserString = `${OS.name} ${browser.name} ${browser.major}`;
  const modelString = `${model.vendor} ${model.model}`;
  const viewPortString = `${window.innerWidth} x ${window.innerHeight}`;
  const standaloneString = window.matchMedia("(display-mode: standalone)")
    .matches
    ? "PWA Installed"
    : "Browser";

  // Check if metric is already sent
  const sendMetric = async (name: string, value: string) => {
    const key = `m-${name}`;
    if (localStorage && localStorage.getItem(key) !== value) {
      await RaccoonAnalytics.metrics.record(name, value);
      localStorage.setItem(key, value);
    }
  };

  sendMetric("OS", osString);
  sendMetric("Browser", browserString);
  sendMetric("Device", modelString);
  sendMetric("ViewPort", viewPortString);
  sendMetric("InstallStatus", standaloneString);
});

export const startModuleTimer = async (module, oldTimer, setUserData) => {
  if (!(await analyticsEnabled)) return;
  // stop old timer
  if (oldTimer) {
    RaccoonAnalytics.timer.stop(oldTimer);
  }
  // start new timer
  RaccoonAnalytics.events.record(
    `Module ${gameData.modules[module].name} started`,
    "Module started"
  );

  const timerId = await RaccoonAnalytics.timer.start(
    `Module ${gameData.modules[module].name}`,
    "Module timers"
  );
  setUserData((data) => {
    return merge({}, data, { timerId });
  });
};
