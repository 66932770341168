import { defaultSettings, gameData } from "./GameData";
import getMonth from "../util/getMonth";
import { migrations } from "./migrations/migrations";

export const defaultUserData: UserData = {
  progression: {
    currentModule: 0,
    currentStage: 0,
    moduleRatings: gameData.modules.map(() => -1),
    previousRatings: gameData.modules.map(() => []),
    checkpoints: [],
    modulesCompleted: [],
  },
  achievements: [],
  settings: defaultSettings,
  bankUpdates: [
    {
      type: "bank",
      amount: 500,
      description: "Spaargeld",
      key: "spaargeld",
    },
    {
      type: "bank",
      amount: 200,
      description: `Inkomsten bijbaan ${getMonth()}`,
      key: "bijbaan",
      monthly: true,
    },
  ],
  userChoices: {},
  computedChoices: {},
  missions: [],
  migrationsExecuted: migrations.map((m) => m.date.toISOString()),
};
