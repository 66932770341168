import React, { useEffect, useState } from "react";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import styled, { ThemeProvider } from "styled-components";
import Theme from "../../theme";
import AlertScreen from "../../components/AlertScreen/AlertScreen";
import ThemeButton from "../../components/ThemeButton/ThemeButton";

import { useUserData } from "../../data/UserData";
import IconButton from "../../components/IconButton/IconButton";
import useHowl from "../../util/useHowl";

// TODO - add event config as prop

const StyledEvent = styled.div`
  color: ${({ theme }) => theme.dark};
  font-weight: 700;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px 0;
  text-align: center;
  gap: 20px;
`;
const orange = Theme("orange");
const Event = ({ config }: { config: IEvent }) => {
  const { getCompiled, incrementStage } = useUserData();
  const [currentStep, setCurrentStep] = useState(0);

  const step = config.steps[currentStep];
  let { buttonText, text, image, header, voiceLine } = { ...config, ...step };

  text = getCompiled(text);
  image = getCompiled(image);
  voiceLine = typeof voiceLine === "function" ? voiceLine() : voiceLine;

  const howl = useHowl(voiceLine);

  useEffect(() => {
    howl.play();
  }, [howl]);

  const [buttonActive, setButtonActive] = useState(false);

  const nextStep = () => {
    setButtonActive(false);
    const nextStepIndex = currentStep + 1;
    if (nextStepIndex === config.steps.length) {
      incrementStage();
      return;
    }
    setCurrentStep(nextStepIndex);
  };

  const onBack = () => {
    setButtonActive(false);
    const nextStepIndex = Math.max(currentStep - 1, 0);
    setCurrentStep(nextStepIndex);
  };

  return (
    <ThemeProvider theme={orange}>
      <AlertScreen
        header={header}
        icon="exclamation"
        showBack={currentStep !== 0}
        onBack={onBack}
      >
        <StyledEvent>
          <MotionConfig
            transition={{
              duration: currentStep === 0 ? 0.8 : 0.4,
              ease: "easeOut",
            }}
          >
            <AnimatePresence mode="wait">
              <motion.img
                key={image}
                style={{
                  width: "100%",
                  maxHeight: "45%",
                  maxWidth: "75%",
                }}
                animate={{
                  scale: [0, 1],
                }}
                exit={{
                  scale: [1, 0],
                }}
                src={image}
              />
            </AnimatePresence>
            <AnimatePresence mode="wait">
              <motion.div
                key={text}
                animate={{
                  opacity: [0, 1],
                }}
                exit={{
                  opacity: [1, 0],
                }}
                className="text"
                onAnimationComplete={() => setButtonActive(true)}
              >
                {text}{" "}
                {voiceLine && (
                  <IconButton
                    icon="Replay"
                    size={26}
                    onClick={howl.replay}
                  ></IconButton>
                )}
              </motion.div>
            </AnimatePresence>
            {buttonText && (
              <ThemeButton
                disabled={!buttonActive}
                as={motion.button}
                onClick={nextStep}
                animate={{
                  y: [100, 0],
                }}
                className="button"
              >
                {buttonText}
              </ThemeButton>
            )}
          </MotionConfig>
        </StyledEvent>
      </AlertScreen>
    </ThemeProvider>
  );
};

export default Event;
