import React, { ComponentProps } from "react";

import styled from "styled-components";
import GradientBlock from "../GradientBlock/GradientBlock";
import Theme from "../../theme";

export const Progress = styled.div<{ progress: number }>`
  position: absolute;
  left: 0;
  height: 100%;
  width: ${(props) => props.progress}%;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.dark},
    ${(props) => props.theme.light}
  );

  will-change: width;
  transition: width 0.2s ease-out;
  border-radius: 50px;
`;

export const StyledProgressBar = styled(GradientBlock)<{
  variant?: string;
  progress?: number;
  maxStars?: number;
  stars?: number;
  left?: number;
  right?: number;
}>`
  position: absolute;
  top: 20px;
  height: 32px;
  left: ${(props) => props.left}px;
  right: ${(props) => props.right}px;

  .inner {
    height: 100%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    color: white;
    font-family: var(--emphasis-font);

    font-size: calc(1em + 1px);
    padding-right: 5px;

    img {
      width: 12px;
      height: 12px;
      margin: 0 3px;
    }

    & > * {
      filter: drop-shadow(0px 1px 1px #00000054);
    }
  }
`;

StyledProgressBar.defaultProps = {
  left: 20,
  right: 20,
};

const theme = Theme("green");
const ProgressBar = ({
  variant,
  progress = 0,
  children,
  ...props
}: ComponentProps<typeof StyledProgressBar>) => {
  return (
    <StyledProgressBar
      role="progressbar"
      aria-label="Progress"
      aria-valuenow={progress + "%"}
      {...props}
    >
      <Progress theme={theme} progress={progress}></Progress>
      {children}
    </StyledProgressBar>
  );
};

export default ProgressBar;
