import React, { ComponentPropsWithRef } from "react";
import styled from "styled-components";

const StyledIcon = styled.svg`
  .color {
    fill: ${(props) => props.theme.main};
  }
`;

export const ExclamationIcon = (props) => (
  <StyledIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="71.386"
    height="67.043"
    viewBox="0 0 71.386 67.043"
  >
    <defs>
      <clipPath id="clip-path">
        <rect width="65.417" height="60.537" fill="none" />
      </clipPath>
    </defs>
    <g transform="translate(6.328) rotate(6)" clipPath="url(#clip-path)">
      <path
        className="color"
        d="M63.457,55.408a11.747,11.747,0,0,1-11.129,5.049L10.562,55.729a12.174,12.174,0,0,1-9.58-7.094A11.269,11.269,0,0,1,2.524,36.973L27.109,4.665A11.738,11.738,0,0,1,47.177,6.928L64.359,43.981a11.871,11.871,0,0,1-.9,11.427"
        fill="#bd4f5c"
      />
      <path
        d="M65.815,30.5,63.953,46.939a2.236,2.236,0,1,1-4.444-.5L61.372,30a2.241,2.241,0,0,1,2.473-1.97,2.4,2.4,0,0,1,1.97,2.473"
        transform="translate(-27.739 -13.06)"
        fill="#fff"
      />
      <path
        d="M62.17,76.534l-.075.667a2.46,2.46,0,1,1-4.888-.554l.075-.666a2.46,2.46,0,1,1,4.888.553"
        transform="translate(-26.666 -34.408)"
        fill="#fff"
      />
      <path
        d="M64.2,49.74,47.02,12.69a7.267,7.267,0,0,0-12.442-1.409L9.989,43.6a6.872,6.872,0,0,0-1.027,7.084,7.712,7.712,0,0,0,6.015,4.507l41.769,4.732a7.249,7.249,0,0,0,6.871-3.047A7.409,7.409,0,0,0,64.2,49.74m-4.75,4.188a1.891,1.891,0,0,1-2.125.884L15.556,50.081a2.362,2.362,0,0,1-1.873-1.338,2.411,2.411,0,0,1,.251-2.222L38.522,14.2a2.324,2.324,0,0,1,1.9-.91A1.688,1.688,0,0,1,42.074,14.6l17.18,37.05c.763,1.212.465,1.853.193,2.272"
        transform="translate(-3.913 -3.907)"
        fill="#fff"
      />
    </g>
  </StyledIcon>
);

export const QuestionMarkIcon = (props) => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="71.386"
      height="67.043"
      viewBox="0 0 71.386 67.043"
    >
      <defs>
        <clipPath id="clip-path">
          <rect width="65.417" height="60.537" fill="none" />
        </clipPath>
      </defs>
      <g transform="translate(6.328) rotate(6)" clipPath="url(#clip-path)">
        <path
          d="M63.457,55.408a11.747,11.747,0,0,1-11.129,5.049L10.562,55.729a12.174,12.174,0,0,1-9.58-7.094A11.269,11.269,0,0,1,2.524,36.973L27.109,4.665A11.738,11.738,0,0,1,47.177,6.928L64.359,43.981a11.871,11.871,0,0,1-.9,11.427"
          transform="translate(0 0)"
          fill="#902df9"
          className="color"
        />
        <path
          d="M64.2,49.74,47.02,12.69a7.267,7.267,0,0,0-12.442-1.409L9.989,43.6a6.872,6.872,0,0,0-1.027,7.084,7.712,7.712,0,0,0,6.015,4.507l41.769,4.732a7.249,7.249,0,0,0,6.871-3.047A7.409,7.409,0,0,0,64.2,49.74m-4.75,4.188a1.891,1.891,0,0,1-2.125.884L15.556,50.081a2.362,2.362,0,0,1-1.873-1.338,2.411,2.411,0,0,1,.251-2.222L38.522,14.2a2.324,2.324,0,0,1,1.9-.91A1.688,1.688,0,0,1,42.074,14.6l17.18,37.05c.763,1.212.465,1.853.193,2.272"
          transform="translate(-3.913 -3.907)"
          fill="#fff"
        />
        <text
          transform="matrix(0.996, 0.087, -0.087, 0.996, 33.125, 45.576)"
          fill="#fff"
          fontSize="39"
          fontFamily="PassionOne-Regular, Passion One"
          letterSpacing="0.04em"
        >
          <tspan x="-7.547" y="0">
            ?
          </tspan>
        </text>
      </g>
    </StyledIcon>
  );
};

const WarningIcon = ({
  icon,
  ...props
}: ComponentPropsWithRef<typeof StyledIcon> & {
  icon: "exclamation" | "question";
}) => {
  if (icon === "question") {
    return <QuestionMarkIcon {...props} />;
  } else if (icon === "exclamation") {
    return <ExclamationIcon {...props} />;
  }
};

export default WarningIcon;
