import QuizmasterDefault from "./char-quiz-default.svg";
import QuizmasterPointing from "./char-quiz-pointing.svg";
import QuizmasterSad from "./char-quiz-sad.svg";
import QuizmasterThumbsUp from "./char-quiz-thumbs-up.svg";
import QuizmasterWave from "./char-quiz-wave.svg";

const Quizmaster = {
  Default: QuizmasterDefault,
  Pointing: QuizmasterPointing,
  Sad: QuizmasterSad,
  ThumbsUp: QuizmasterThumbsUp,
  Wave: QuizmasterWave,
};

export default Quizmaster;
