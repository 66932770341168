import Bliss from "./char-aansprakelijkheidsverzekering-bliss.svg";
import Calling from "./char-aansprakelijkheidsverzekering-calling.svg";
import Default from "./char-aansprakelijkheidsverzekering-default.svg";
import Explaining from "./char-aansprakelijkheidsverzekering-explaining.svg";
import FolderInHand from "./char-aansprakelijkheidsverzekering-folder in hand.svg";
import PhoneInHand from "./char-aansprakelijkheidsverzekering-phone in hand.svg";
import QuestionSad from "./char-aansprakelijkheidsverzekering-question sad.svg";
import Wink from "./char-aansprakelijkheidsverzekering-wink.svg";

const AansprakelijkheidsCharacter = {
  Bliss,
  Calling,
  Default,
  Explaining,
  FolderInHand,
  PhoneInHand,
  QuestionSad,
  Wink,
};
export default AansprakelijkheidsCharacter;
