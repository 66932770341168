import React from "react";
import { parseUserAgent } from "react-device-detect";

const device = parseUserAgent(navigator.userAgent);
const OS = device.os;
const browser = device.browser;
const model = device.device;

const osString = `${OS.name} ${OS.version}`;
const browserString = `${OS.name} ${browser.name} ${browser.major}`;
const modelString = `${model.vendor} ${model.model}`;
const viewPort = `${window.innerWidth} x ${window.innerHeight}`;
const standalone = window.matchMedia("(display-mode: standalone)").matches;

const Device = () => {
  return (
    <div
      style={{
        padding: 20,
        wordBreak: "break-all",
      }}
    >
      <div>{JSON.stringify(OS)}</div>
      <div>{JSON.stringify(browser)}</div>
      <div>{JSON.stringify(model)}</div>

      <div>{osString}</div>
      <div>{browserString}</div>
      <div>{modelString}</div>
      <div>{viewPort}</div>
      <div>standalone: {standalone.toString()}</div>
    </div>
  );
};

export default Device;
