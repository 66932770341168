import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import ThemeButton from "../../components/ThemeButton/ThemeButton";
import Circles from "../../components/Circles/Circles";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserData } from "../../data/UserData";

import Silver from "../../assets/stars/star-gray.svg";
import Gold from "../../assets/stars/star-gold-1.svg";
import Noah from "../../assets/characters/Noah/Noah";
import { useAudio } from "../../assets/audio/Audio";
import ReplayPopUp from "./ReplayPopUp";
import { getResetFn } from "../../util/getResetFn";

const images = [
  Noah.OneStar,
  // Jane.TwoStars,
  Noah.ThreeStars,
  // Jane.FourStars,
  Noah.FiveStars,
];

const StyledRating = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 40px;

  .circles {
    position: absolute;
    width: 100%;
    display: grid;
    place-items: center;

    z-index: -1;

    .circle {
      position: absolute;
      width: 600px;
      height: 600px;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        ${({ theme }) => theme.dark} 0%,
        ${({ theme }) => theme.light} 100%
      );

      &:nth-child(2) {
        width: 500px;
        height: 500px;

        background: linear-gradient(
          180deg,
          ${({ theme }) => theme.dark} 0%,
          ${({ theme }) => theme.main} 100%
        );
      }
    }
  }

  .module-name {
    color: white;
    text-align: center;
    position: relative;
  }

  .rating {
    color: white;
    text-align: center;
    margin-top: 20px;
    font-size: 4.25em;
    position: relative;
  }

  .stars {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    & > div {
      position: relative;
    }

    .star {
      width: 36px;
      width: max(36px, min(8cqmax, 12cqmin));
      object-fit: contain;

      &.gold {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;

    img {
      margin: 0 auto;
      max-height: 300px;
      max-height: max(300px, 50cqh);
    }

    .buttons {
      position: absolute;
      width: 100%;
      bottom: 0;
      display: flex;
      flex-direction: column;
      padding: 0 10px 20px;
      gap: 10px;
    }
  }
`;

const transition = (delay) => ({
  delay,
  duration: 0.8,
  ease: "easeOut",
});

const circleVariants = {
  visible: (delay = 0) => ({
    y: 0,
    transition: transition(delay),
  }),
  hidden: {
    y: "-50%",
  },
};

const starVariants = {
  silverVisible: {
    opacity: 1,
    scale: 1,
    transition: transition(0.2),
  },
  silverHidden: {
    opacity: 0,
    scale: 0,
  },
  goldHidden: (i) => ({
    y: -200,
    x: 100 * (i - 2),
  }),
  goldVisible: (i) => ({
    y: 0,
    x: 0,
    transition: {
      delay: 1 + i * 0.2,
      duration: 0.8,
      ease: "easeOut",
    },
  }),
};

const circleAnim = {
  variants: circleVariants,
  initial: "hidden",
  animate: "visible",
};

const ModuleRating = () => {
  const { userData, setUserData } = useUserData();
  const location = useLocation();
  const audio = useAudio();
  const navigate = useNavigate();
  const [resetModal, setResetModal] = useState(false);

  const module = location.state?.module ?? userData.progression.currentModule;

  const reset = getResetFn(module, setUserData, navigate, true);

  useEffect(() => {
    if (location.state?.module && userData.demo) {
      reset();
    } else audio.play("collectStar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moduleRatings = userData.progression.moduleRatings;

  const score = moduleRatings[module];

  let rating = 3;
  if (score < 50) {
    rating = 1;
  } else if (score < 100) {
    rating = 2;
  }

  const affirmation = {
    1: "Goed gedaan!",
    2: "Lekker bezig!",
    3: "Perfect!",
  }[rating];

  return (
    <StyledRating key={rating}>
      <Circles
        playSound={false}
        style={{
          position: "absolute",
          left: "-50%",
          right: "-50%",
          bottom: 200,
        }}
        {...circleAnim}
      ></Circles>

      <motion.div
        animate={{
          opacity: [0, 1],
          fontSize: ["0px", "1.625em"],
        }}
        transition={transition(0)}
        className="module-name emphasis"
        aria-live="polite"
      >
        {affirmation}
      </motion.div>
      <div className="stars" aria-hidden>
        {[0, 1, 2].map((i) => (
          <div key={i}>
            <motion.img
              className="star"
              src={Silver}
              variants={starVariants}
              animate="silverVisible"
              initial="silverHidden"
            ></motion.img>
            {i + 1 <= rating && (
              <motion.img
                className="star gold"
                custom={i}
                src={Gold}
                variants={starVariants}
                animate="goldVisible"
                initial="goldHidden"
                alt="gouden ster"
              ></motion.img>
            )}
          </div>
        ))}
      </div>
      <motion.div
        className="rating emphasis"
        animate={{
          opacity: [0, 1],
          fontSize: ["0px", "4.25em"],
          transition: {
            delay: 1.8,
            duration: 0.8,
            ease: "easeOut",
          },
        }}
        aria-label={`Je hebt ${rating} van de 3 sterren verdiend.`}
        role="text"
      >
        {rating}/3
      </motion.div>
      <div className="bottom">
        <motion.img
          animate={{
            y: ["100%", "0%"],
            rotate: ["0deg", "-3deg"],
          }}
          transition={transition(2.6)}
          src={images[rating - 1].toString()}
          alt="blij karakter"
        ></motion.img>
        <div className="buttons">
          {reset && (
            <ThemeButton
              as={motion.button}
              animate={{
                y: [200, 0],
              }}
              transition={transition(2.6)}
              onClick={() => setResetModal(true)}
            >
              Opnieuw spelen
            </ThemeButton>
          )}

          <ThemeButton
            as={motion.button}
            animate={{
              y: [200, 0],
            }}
            transition={transition(2.7)}
            onClick={() => navigate(-1)}
          >
            Verder
          </ThemeButton>
        </div>
      </div>
      {resetModal && (
        <ReplayPopUp onCancel={() => setResetModal(false)} onConfirm={reset} />
      )}
    </StyledRating>
  );
};

export const PlayModule = () => {
  const { userData, setUserData } = useUserData();
  const location = useLocation();
  const audio = useAudio();
  const navigate = useNavigate();

  const module = location.state?.module ?? userData.progression.currentModule;

  const reset = getResetFn(module, setUserData, navigate, true);

  useEffect(() => {
    if (location.state?.module && userData.demo) {
      reset();
    } else audio.play("collectStar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledRating>
      <Circles
        playSound={false}
        style={{
          position: "absolute",
          left: "-50%",
          right: "-50%",
          bottom: 200,
        }}
        {...circleAnim}
      ></Circles>

      <motion.div
        animate={{
          opacity: [0, 1],
          fontSize: ["0px", "1.625em"],
        }}
        transition={transition(0)}
        className="module-name emphasis"
        aria-live="polite"
      >
        Je hebt deze zaak nog niet geregeld.
        <br />
        <br />
        Wil je dat nu doen?
      </motion.div>

      <div className="bottom">
        <motion.img
          animate={{
            y: ["100%", "0%"],
            rotate: ["0deg", "-3deg"],
          }}
          transition={transition(0.8)}
          src={images[1].toString()}
          alt="blij karakter"
        ></motion.img>
        <div className="buttons">
          {reset && (
            <ThemeButton
              as={motion.button}
              animate={{
                y: [200, 0],
              }}
              transition={transition(0.8)}
              onClick={reset}
            >
              speel
            </ThemeButton>
          )}

          <ThemeButton
            as={motion.button}
            animate={{
              y: [200, 0],
            }}
            transition={transition(0.8)}
            onClick={() => navigate(-1)}
          >
            Terug
          </ThemeButton>
        </div>
      </div>
    </StyledRating>
  );
};

export default ModuleRating;
