import { useEffect } from "react";

let cityScrollPosition = 0;

export const useScrollPosition = (ref) => {
  useEffect(() => {
    const current = ref.current;
    current.scrollLeft = cityScrollPosition;
    const handleScroll = () => {
      cityScrollPosition = ref.current.scrollLeft;
    };
    current.addEventListener("scroll", handleScroll);
    return () => {
      current.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
